.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom */
#table-full table,
#table-full td,
#table-full th {
  border: 1px solid #d3d3d3;
  text-align: center;
  /* padding-top: 5px;
  padding-bottom: 5px; */
}

table {
  width: 100%;
  border-collapse: collapse;
}

#table-no-tb table,
#table-no-tb td,
#table-no-tb th {
  border-left: 1px solid #d3d3d3;
  border-right: 1px solid #d3d3d3;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}
#table-full td,
#table-no-tb td {
  height: 50px; /* content height 50px */
  padding: 0;
}

.result-number-big {
  font-size: 28px;
  font-weight: bold;
  color: #cd0404;
  font-family: 'Arial, Tahoma, Helvetica', sans-serif;
}

.text-red {
  color: #cd0404;
  font-size: 26px;
  font-weight: bold;
}

.result-number-black {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  font-family: 'Arial, Tahoma, Helvetica', sans-serif;
}

.border-tb-right {
  border-right: 1px solid #d3d3d3;
}

.content-number-center {
  height: 50px; /* content height 50px */
  justify-content: center;
  align-items: center;
  display: flex;
}

.nav-link {
  color: #ffffff;
}

/* Loading */
.loader {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #118a0f;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-bottom: 4px solid #ff3d00;
  border-left: 4px solid transparent;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom-menu-url:hover {
  text-decoration: underline;
}

/* Custom */
/* Custom */
/* Custom */
/* Custom */
/* Custom */
/* Custom */
/* Custom */
.error,
.lotoId15,
.lotoId16,
.lotoId17,
.lotoId19,
.lotoId20,
.lotoId21,
.lotoId22,
.lotoId28,
.lotoId29,
.lotoId3,
.lotoId30,
.lotoId31,
.lotoId32,
.lotoId33,
.lotoId34,
.lotoId35,
.lotoId5,
.lotoId8,
.lotoId9,
.mgt5,
.news-lists,
.result-header .title-link-item,
.select-loto .lotoId4,
.table-tructiep .result-header .title-link-item,
.title-region-link,
.vl-box .title-kq {
  margin-top: 5px;
}

.ajax-container,
.box-login-register,
.contact-content,
.live-menu-item,
.modal-body,
.napthe,
.relative,
.widget-header {
  position: relative;
}

.live-1,
.live-3,
.uppercase-text {
  text-transform: uppercase;
}

.btn,
.btn-agree,
.embed-links a i {
  vertical-align: middle;
}

.breadcrumb > li,
.dropdown-menu,
.result-max4d li,
.tabs,
.tags ul,
ol,
ul {
  list-style: none;
}

.breadcrumb > li,
.btn,
.btn-agree,
.play-now,
.smartbanner-container {
  white-space: nowrap;
}

.Zebra_DatePicker table,
table {
  border-collapse: collapse;
  border-spacing: 0;
}

#offcanvas-menu a,
a {
  text-decoration: none;
}

html {
  background: #fff;
}

blockquote,
body,
code,
dd,
div,
dl,
dt,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
input,
legend,
li,
ol,
p,
pre,
td,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
}

fieldset,
img {
  border: 0;
}

address,
caption,
cite,
code,
dfn,
em,
th,
var {
  font-style: normal;
  font-weight: 400;
}

caption,
th {
  text-align: left;
}

q:after,
q:before {
  content: '';
}

abbr,
acronym {
  border: 0;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

.number-tong,
.red,
.sidebar-menu .items .item.active .item-name a {
  color: #e62e04 !important;
}

.blue {
  color: #2d4c87 !important;
}

.menu-diw li.active a,
.white {
  color: #fff !important;
}

.bold {
  font-weight: 700 !important;
}

.action-link,
.ads-overlay-bottom,
.bv-mobile,
.checkbox input[type='checkbox'],
.copyright-img,
.ddxsmb .response .header-title-blue,
.hidden,
.icon-lich,
.lightbox-content .btn-tkts,
.lightbox-content .form-input.tansuat .ketquavn-social,
.link-social,
.loader-tk,
.loto-input .select-loto .loto-xien div.rCheck input[type='radio'],
.loto-xien .note,
.message-mobile,
.print-kqxs::after,
.radio input[type='radio'],
.show-mobile,
.show-more br,
.today-open-widget .icon,
.toggle-btn-box {
  display: none;
}

#center,
.btn {
  display: inline-block;
}

.fixed {
  top: 0;
}

#left-big .breadcrumb li:first-child,
.article .likeshare,
.digits-form .radio,
.first-table,
.form-group,
.google-auto-placed,
.kq-table-hover tr th .show-mobile,
.main-left .breadcrumbs .breadcrumb li:nth-child(2n),
.mdb6,
.navbar-nav > li,
.pull-left,
.result-box span.col-xs-3,
.result-box span.col-xs-4,
.result-box span.col-xs-5,
.result-box span.col-xs-6,
.tabs li,
.top-nav h1,
.top-social-ketquavn {
  float: left;
}

.pagination ul,
.pull-right,
.top-btns-login,
table.list-haydung tr .solanxem {
  float: right;
}

.power-mega table tr td,
.text-center,
.vl-max3d table tr td,
.vl-max4d table tr td {
  text-align: center !important;
}

.lich-quay-xo-so .table tr,
.table-left tr td,
.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.error {
  clear: both;
}

.error a,
.user-profile a {
  color: #00f !important;
}

.clear,
.clearfix,
.loto_today:after {
  clear: both;
}

.modal-body .widget,
.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-border-bottom {
  border-bottom: none !important;
}

.no-border {
  border: none !important;
}

.kq-link,
.loto_today .small {
  color: #00f;
}

.mb_g0 .sblue,
.sblue {
  color: #00f !important;
  font-weight: 700;
}

.ajax-container.soketqua-container .result-box,
.mgt10,
.vl-box .mb_date_info {
  margin-top: 10px;
}

.button-submit-break,
.loto-input .input,
.loto_today .landing-thongke ul,
.mgt15,
section.related {
  margin-top: 15px;
}

#offcanvas-menu .copyright,
.box-search-sqk .skq-btn,
.exchange-links,
.mgt20 {
  margin-top: 20px;
}

.mgt25 {
  margin-top: 25px;
}

.box-search-sqk select,
.mgb5 {
  margin-bottom: 5px;
}

#tksc-list .tksc-item .tksc-name,
.date-ranger-sc,
.dientoan:last-child,
.link-social-image,
.mgb10,
.search-widget .search-row,
.widget,
.widget-sc {
  margin-bottom: 10px;
}

.input2-tansuat .label,
.mgb15,
.table-prediction {
  margin-bottom: 15px;
}

.mgb20 {
  margin-bottom: 20px;
}

.date-next,
.leftarrowclass,
.mgr5,
.tag_games .game-item:nth-child(3n) {
  margin-right: 5px;
}

.mgr8 {
  margin-right: 8px;
}

.box-search-sqk input,
.checkbox label:before,
.coin-slider,
.diw-widget a,
.loto-input .input .numbers,
.mgr10 {
  margin-right: 10px;
}

.mr15 {
  margin-right: 15px;
}

.loto_today .info-today-parent,
.pd15 {
  padding: 15px;
}

.dientoan-home h2,
.pd5,
table.list-haydung tr td {
  padding: 5px;
}

.info-result ul.sub-content,
.pdl10,
.widget-header,
input.form-control,
select.form-control,
textarea.form-control {
  padding-left: 10px;
}

.box-search-sqk,
.pd10,
.result-table tbody tr td {
  padding: 10px;
}

.pdb10 {
  padding-bottom: 10px;
}

.pdb5 {
  padding-bottom: 5px;
}

.height-60 {
  height: 60px !important;
}

.advertisment img,
.box-search-sqk .Zebra_DatePicker_Icon_Wrapper,
.col-xs-1,
.col100,
.info-result .table-award,
.kq-calendar-content,
.kq-table-hover td.info .btn-default.show-mobile,
.lightbox-content .table-extend tbody,
.list-game,
.loto-input .input input.form-control,
.loto-nourish table,
.main-tk-sc .Zebra_DatePicker_Icon_Wrapper,
.result-box-loto .dd-loto-widget table,
.sc-lt .Zebra_DatePicker_Icon_Wrapper,
.sidebar-menu .items,
.today-open-region-widget .region-wrapper,
.w100,
.width100 {
  width: 100%;
}

.sblue {
  font-size: 25px;
}

.mb_g0 .sblue {
  font-size: 32px;
}

.sblue2 {
  font-weight: 700;
  font-size: 15px;
  color: #00f;
}

.sRed {
  font-weight: 700;
  font-size: 16px;
  color: red;
}

.result-table tbody tr td .date a,
.small {
  font-size: 11px;
}

.message-404,
.message-404 a,
.result-table tbody tr td .number-full .rc00,
.title-sn {
  font-size: 20px;
}

.alert {
  padding: 10px;
  border: 1px solid transparent;
  border-radius: 4px;
  margin-bottom: 10px;
}

.alert-danger {
  color: #b94a48;
  background-color: #f2dede;
  border-color: #eed3d7;
}

.alert-success {
  border-color: #8ac38b;
  color: #356635;
  background-color: #cde0c4;
}

.message-404 {
  font-weight: 700;
  margin: 20px;
}

#container,
.header-wrapper,
.top-nav {
  width: 1170px;
  margin: 0 auto;
}

.widget-header {
  background-color: #2d4c87;
  height: 35px;
  padding-right: 10px;
}

.widget-header .widget-header-hr {
  width: 77px;
  height: 3px;
  background: #73a81f;
  position: absolute;
  top: 0;
  left: 10px;
}

.widget-header h3,
.widget-header h3 a {
  color: #fff;
  padding-top: 10px;
  font-weight: 700;
}

.table-tructiep .table tr.gdb td,
.table-tructiep .table tr.gdb td .number,
.tienich-item i:hover,
.widget-header h3 a:hover {
  color: #e62e04;
}

.widget-content {
  border: 1px solid #d8d7d7;
  border-top: none;
}

.top-nav {
  height: 32px;
  line-height: 32px;
}

.top-nav .intro-text {
  width: 550px;
  float: left;
  font-size: 13px;
}

.intro-text a,
.sms-text {
  font-size: 12px;
}

.top-nav .top-btns {
  float: right;
  text-align: right;
}

.top-nav .top-btns a,
.top-nav .top-btns-login a {
  padding: 9px 15px;
}

.top-nav .top-btns a:first-child,
.top-nav .top-btns-login a:first-child {
  border-right: 1px solid #ddd;
  padding-left: 0;
}

.top-nav .top-btns a:last-child,
.top-nav .top-btns-login a:last-child {
  padding-right: 0;
}

.header-content {
  background: #f7f7f7;
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
}

.logo {
  float: left;
  padding: 8px 0;
}

.slogan {
  margin-top: 5px;
  font-size: 11.3px;
  font-style: italic;
}

.slogan-footer {
  margin-top: 5px;
  font-size: 12px;
}

.header-banner {
  width: 825px;
  height: 85px;
  float: right;
  margin: 5px 0;
}

.main-content {
  min-height: 500px;
}

#left {
  width: 255px;
  float: left;
}

.live-menu-item {
  height: 51px;
  font-size: 11px;
  font-weight: 700;
  border: 1px solid #ccc;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  text-align: left;
  margin-bottom: 5px;
  background: #fbfbfb;
}

.live-menu-icon {
  float: left;
  width: 55px;
  height: 40px;
  margin: 5px 0 0 5px;
}

.live-menu-content {
  float: right;
  width: 115px;
  margin-left: 10px;
  padding: 10px 0;
}

.live-menu-content p,
.live-menu-content p a {
  text-transform: uppercase;
  font-size: 11px;
}

.live-1,
.live-3 {
  position: absolute;
  padding: 2px 5px;
  color: #fff;
  font-size: 10px;
  right: 3px;
  top: 3px;
}

.live-1,
.quaythu .widget-content tr td.active,
.top-menu-popup li.root a:hover {
  background: #74aa19;
}

.live-3,
.toggle-tong:checked + .toggle-switch,
.top-social-ketquavn ul li a.btn-set-homepage:hover {
  background: #e62e04;
}

.kq-calendar {
  position: relative;
  background: #fff;
  border: 1px solid #cdcdcd;
  border-bottom: none;
  border-top: none;
}

.kq-calendar-top {
  height: 25px;
  width: 100%;
  background-color: #f0f0f0;
}

.copyright,
.copyright-img,
.info-result .table-award th,
.kq-calendar-top td {
  text-align: center;
  font-weight: 700;
}

.kq-calendar-top td:first-child,
.kq-calendar-top td:last-child {
  width: 20px;
}

.kq-calendar-content td a,
.kq-calendar-content td span {
  padding: 5px;
  font-weight: 400;
}

.kq-calendar-content td a.silver,
.kq-calendar-content td span.silver {
  color: silver;
  font-weight: 400;
}

.kq-calendar-header {
  height: 25px;
  line-height: 25px;
}

.kq-calendar-header th {
  font-size: 13px;
  height: 30px;
  text-align: center;
}

.kq-calendar-content td {
  width: 9%;
  border: 1px solid #ddd;
  margin: 0;
  outline: 0;
  text-align: center;
  height: 23px;
  line-height: 23px;
  border-left: none;
}

.kq-calendar-content td:hover {
  background: #73a81f;
}

.kq-calendar-content .weekend {
  background-color: #f9f9ed;
}

.kq-calendar-content td.selected {
  background: #73a81f;
  font-weight: 700;
}

.btn-inline-default:hover,
.day-skq,
.ddsmoothmenu ul li a:link,
.ddsmoothmenu ul li a:visited,
.ddsmoothmenu ul li.home a i,
.kq-calendar-content td.selected a,
.lich-quay-xo-so tr.header-title th,
.lich-quay-xo-so tr.header-title th a,
.loto-mien ul li a,
.pagination ul li span,
.pagination ul li.active a,
.pagination ul li:hover a,
.quaythu .widget-content tr td.active a,
.title-text span {
  color: #fff;
}

.kq-calendar-content td.today {
  border: 2px solid #e62e04 !important;
}

.ddsmoothmenu > ul > li:last-child a,
.kq-calendar-content tr td:last-child,
.result-box table tr td:last-child,
.table-tructiep .table tr td,
.tk-nhanh-40 table tr td:last-child,
.today-open-region-widget tr td:last-child {
  border-right: none;
}

.news-widget .widget-content ul,
.tk-sc-menu {
  padding: 0 7px;
}

.tk-sc-menu li {
  height: 36px;
  line-height: 36px;
  border-bottom: 1px inset #ebebeb;
}

.region-column ul li:last-child,
.result-box table tr:last-child td,
.sms-item:last-child,
.tk-sc-menu li:last-child {
  border-bottom: none;
}

.tk-sc-menu li a i {
  margin-left: 3px;
  margin-right: 8px;
}

.tienich-item {
  float: left;
  width: 87px;
  height: 33px;
  background: #fff;
  margin-bottom: 5px;
  text-align: center;
  padding: 10px 0;
}

.live-2,
.new {
  padding: 2px 5px;
  color: #fff;
  text-transform: uppercase;
}

.date-pre,
.tienich-item:nth-child(2n) {
  margin-left: 5px;
}

.tienich-widget .widget-content {
  background: #2d4c87;
  padding: 0 5px;
  border: none;
}

.box-table .margin-row,
.tienich-item p:first-child {
  margin-bottom: 4px;
}

.tienich-item i {
  font-size: 20px;
  color: #626262;
  font-weight: 400;
}

.tienich-item a {
  font-size: 9px;
  text-transform: uppercase;
  font-weight: 400;
}

#center {
  width: 575px;
  margin-left: 10px;
}

#right,
#right-lt-tk {
  float: right;
  margin-left: 10px;
  width: 320px;
}

#search-box-content .form-control {
  min-width: 130px !important;
}

.search-widget .widget-content {
  padding: 6px;
}

.lotto-result .result-max4d li:last-child,
.search-widget .search-row:last-child {
  margin-bottom: 0;
}

.search-widget input {
  height: 30px;
  padding: 0 10px;
  width: 125px;
}

.search-widget select {
  width: 130px;
  height: 30px;
  margin-left: 5px;
}

.search-widget .search-button {
  margin-left: 8px;
  height: 30px;
  border: 0;
  cursor: pointer;
  background-color: #73a81f;
  border-radius: 2px;
  color: #fff;
  width: 130px;
}

.date-chooser,
.search-widget .search-number {
  height: 28px !important;
}

.search-widget input,
.search-widget select {
  line-height: 1.42857;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.province-list li,
.region-column ul li {
  height: 31px;
  line-height: 31px;
  padding-left: 10px;
}

.search-widget input:focus,
.search-widget select:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
    0 0 8px rgba(102, 175, 233, 0.6);
}

.search-widget
  .search-row
  .Zebra_DatePicker_Icon_Wrapper
  .Zebra_DatePicker_Icon_Inside {
  top: 7px;
  right: 4px !important;
  left: auto !important;
}

.new {
  background: #e50003;
  font-size: 8px;
  margin-left: 8px;
  font-weight: 700;
}

.live-2 {
  background: #74aa19;
  font-size: 10px;
  margin-left: 10px;
  font-weight: 700;
}

.lightbox-content .form-input.tansuat .checkbox label,
.view-more {
  background: 0 0 !important;
}

.view-more {
  border: none !important;
}

.view-more a {
  color: #2d4c87;
  font-size: 11px;
}

.lich-quay-xo-so tr.header-title th a:hover,
.loto-mien ul li.active a,
.view-more a:hover {
  text-decoration: underline;
}

.sms-item {
  padding: 7px 10px;
  border-bottom: 1px solid #cdcdcd;
}

.sms-icon {
  float: left;
  width: 62px;
  text-align: center;
  padding-top: 10px;
}

.sms-content {
  float: left;
  width: 190px;
}

.Zebra_DatePicker .dp_daypicker,
.Zebra_DatePicker .dp_footer,
.Zebra_DatePicker .dp_monthpicker,
.Zebra_DatePicker .dp_yearpicker,
.sms-syntax-content {
  margin-top: 3px;
}

.sms-syntax {
  width: 62px;
  float: left;
  text-align: center;
  border: 1px solid #74aa19;
  border-top: 1px solid #74aa19;
  border-radius: 3px;
  font-weight: 700;
  padding: 3px 9px;
}

.province-list li,
.region-column {
  border: 1px solid #ddd;
  float: left;
}

.dd-loto-widget table th,
.dd-loto-widget table tr td,
.result-box table tr td {
  border: 1px solid #d8d7d7;
}

.sms-syntax-icon {
  float: left;
  width: 30px;
  margin: 6px 0 0 20px;
}

#right-article,
.dd-loto-widget .dit-loto,
.download-ios,
.sms-box-item:nth-child(2n) {
  margin-left: 10px;
}

.sms-syntax-number {
  float: left;
  font-size: 18px;
  padding-top: 0;
}

.region-column {
  width: 200px;
  border-bottom: none;
}

.region-column:first-child,
.result-box table tr td:first-child,
.table-tructiep .table thead tr th:first-child,
.tk-nhanh-40 table tr td:first-child,
.today-open-region-widget tr td:first-child {
  border-left: none;
}

.region-wrapper tr td:nth-child(2n) .region-column {
  border-left: none;
  width: 170px;
}

.region-column ul li {
  border-bottom: 1px solid #d8d7d7;
}

.region-column ul li a i {
  margin-right: 2px;
}

.province-list li {
  border-top: none;
  border-left: none;
  width: 33.333333333333%;
}

.result-header h1,
.result-header-small h1 {
  color: #e00000;
  font-size: 16px;
  text-transform: uppercase;
}

.result-header h2,
.result-header h4 {
  color: #2d4c87;
  font-size: 15px;
}

.result-header,
.result-header-small {
  text-align: center;
  padding: 8px 0;
  position: relative;
}

.result-header .print-kqxs {
  position: absolute;
  right: 15px;
  top: 10px;
}

.result-box table {
  width: 100%;
  border: none;
  table-layout: fixed;
}

.result-box table tr td {
  height: 38px;
  line-height: 38px;
  text-align: center;
  font-weight: 700;
  font-size: 23px;
  color: #333;
}

.result-box table tr td span,
.result-box table tr td span mark,
.result-box-dt .title-kq,
.result-box-dt tr .number strong,
.result-header .title-xsmb {
  font-size: 22px;
}

.result-box table tr span.mb_g0,
.result-box table tr td span.red mark,
.result-box table tr td.mb_g0 {
  font-size: 30px;
}

.result-box table tr td[colspan='3'] {
  width: 70%;
}

.result-box table tr td[colspan='4'] {
  width: 30%;
}

.col-xs-2,
.col-xs-6,
.col50,
.result-box table tr td[colspan='6'],
.sidebar-menu .logo img {
  width: 50%;
}

.first-col {
  width: 97px;
  font-size: 14px !important;
}

.result-footer {
  padding: 10px;
  text-align: center;
  color: #2d4c87;
}

.dbnmfirst,
.lich-quay-xo-so tbody tr td p a,
.loto-tructiep tr td.dd-stt span,
.result-footer,
.result-footer span,
.s15,
.table-prediction tr td.value,
.table-prediction tr td.value span,
.tk-nhanh-number .bold,
.tktc tr td .red,
.tr-tkmaxgan td,
.widget-skq-loto table tr td.dd-kq {
  font-size: 15px;
}

#footer,
.dd-loto-widget table {
  font-size: 13px;
  text-align: center;
}

.dd-loto-widget table {
  width: 100%;
  height: 320px;
  float: left;
}

.dd-loto-widget table th {
  font-weight: 700;
  text-align: center;
  height: 35px;
  line-height: 35px;
  color: #900;
  font-size: 14px;
  background-color: #fafafa;
}

.dd-loto-widget table tr:first-child td,
.table-tructiep .control-panel {
  border-top: none;
}

.dd-loto-widget table tr td.dd-stt {
  color: #900;
  font-size: 15px;
}

.dd-loto-widget table tr td.dd-kq {
  text-align: left;
  padding-left: 10px;
  line-height: 25px;
  font-weight: 700;
  color: #333;
}

.tk-nhanh-item-header {
  height: 30px;
  line-height: 30px;
  background: #efeeee;
  border-left: 3px solid #76ab1c;
  font-weight: 700;
  padding-left: 10px;
  font-size: 14px;
}

.action ul li a,
.ddsmoothmenu ul li a,
.news-widget li a,
.region-column a,
.s14,
.so-mo tr td,
.thongkedauduoi tr td a,
.thongkedauduoi tr th,
.tk-nhanh-item-header .red,
.tk-sc-menu a,
.today-open-region-widget a,
.widget-skq-loto table th,
.widget-skq-loto table tr td.dd-stt {
  font-size: 14px;
}

.tk-nhanh-item-content {
  padding: 13px;
}

.tk-nhanh-item {
  width: 110px;
  height: 29px;
  line-height: 29px;
  float: left;
  margin-right: 13px;
  font-weight: 700;
}

.header-title-silver,
.title-text {
  line-height: 37px;
  padding-left: 10px;
}

.box-capso:nth-child(5n),
.box-table .number:last-child,
.diw-widget a:last-child,
.list-game .game-item:nth-child(3n),
.loto-input .select-loto .loto-xien:last-child,
.sidebar-menu .items .item:nth-child(3n),
.tk-nhanh-item:nth-child(4n),
.top-social-ketquavn ul li:last-child {
  margin-right: 0;
}

.tk-nhanh-number {
  border: 1px solid #a1b1cf;
  width: 45px;
  font-size: 15px;
}

.tk-nhanh-text {
  border: 1px solid #e3e3e3;
  border-left: none;
  background: #fcfaf4;
}

.update {
  color: #fff;
  font-size: 12px;
  float: right;
}

#footer {
  background-color: #f7f7f7;
  border-top: 1px outset #ededed;
  padding: 10px 0;
  margin-top: 10px;
}

.contact,
.loto-tructiep tr:first-child td,
.result-box .col-xs-6,
.table tr,
.xoso360-social {
  text-align: center;
}

.exchange-links a {
  padding: 5px;
  font-weight: 700;
}

.menu-footer {
  margin: 0 auto;
  text-align: center;
}

.menu-footer .nav-menu {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
}

.menu-footer .item-link {
  font-size: 15px;
  padding-right: 20px;
  cursor: pointer;
  color: #333;
}

.ddsmoothmenu {
  height: 40px;
  background: #2d4c87;
  width: 1170px;
  margin: 10px auto;
}

.ddsmoothmenu ul {
  z-index: 100;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.ddsmoothmenu ul li {
  position: relative;
  display: inline;
  float: left;
}

.ddsmoothmenu ul li a {
  text-transform: uppercase;
  padding: 0 10px;
  background: #2d4c87;
  display: block;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  text-decoration: none;
  font-weight: 400;
  line-height: 40px;
}

.ddsmoothmenu ul li a:hover {
  background: #325494;
}

.ddsmoothmenu ul li.home {
  line-height: 16px;
}

.ddsmoothmenu ul li ul {
  top: 39px !important;
  position: absolute;
  left: -3000px;
  display: none;
  visibility: hidden;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.ddsmoothmenu ul li ul li {
  display: list-item;
  float: none;
  position: static;
}

.ddsmoothmenu ul li ul li ul {
  position: absolute;
  top: -1px !important;
}

.ddsmoothmenu ul li ul li a {
  width: 160px;
  margin: 0;
  border-top-width: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  text-transform: none;
}

* html .ddsmoothmenu {
  height: 1%;
}

.downarrowclass {
  position: absolute;
  top: 12px;
  right: 7px;
}

.rightarrowclass {
  position: absolute;
  top: 6px;
  right: 5px;
}

.ddshadow {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  background-color: #ccc;
}

.toplevelshadow {
  margin: 5px 0 0 5px;
  opacity: 0.8;
}

.ddcss3support .ddshadow.toplevelshadow {
  margin: 0;
}

.ddcss3support .ddshadow {
  background-color: transparent;
  box-shadow: 5px 5px 5px #aaa;
  -moz-box-shadow: 5px 5px 5px #aaa;
  -webkit-box-shadow: 5px 5px 5px #aaa;
}

#province-list {
  width: 610px !important;
  background: #2d4c87;
  border: none;
  display: block;
}

.top-menu-popup li {
  border: none !important;
  float: left !important;
}

.top-menu-popup li a {
  width: 178px !important;
  margin: 0 !important;
  padding: 10px 12px !important;
}

.top-menu-popup li.root {
  width: 100% !important;
  display: block;
  float: none !important;
  text-align: center !important;
  clear: both;
  background: #74aa19 !important;
  padding: 0;
}

.top-menu-popup li.root a {
  background: #74aa19;
  width: 570px !important;
  border: none !important;
  text-transform: uppercase !important;
  padding: 11px 12px !important;
}

#left-big {
  width: 840px;
  float: left;
  min-height: 200px;
}

.header-title {
  height: 37px;
  background: #e62e04;
}

.header-title-gray {
  background: #737373;
  height: 37px;
  border: 1px solid #626262;
}

.header-title-dark-blue {
  background: #2d4c87;
  height: 37px;
  border: 1px solid #626262;
}

.header-title-silver {
  height: 37px;
  background: #f1f1f1;
  border: 1px solid #eaeaea;
  margin-bottom: 10px;
}

.title-text {
  color: #fff;
}

.caption-info {
  margin-top: 10px;
  background: #f4f2e6;
  border: 1px solid #e3dfc9;
  padding: 15px;
  font-size: 14px;
  color: #6d663e;
  line-height: 18px;
}

.box-ketqua,
.full-form button,
.full-form input {
  float: left;
  width: 100%;
}

.box-ketqua .result-box {
  width: 480px;
  float: left;
}

.result-box-loto {
  margin-left: 15px;
  float: left;
  width: 204px;
}

.form-group-main {
  margin: 25px 0;
  min-height: 20px;
}

#tksc-list .tksc-item .tksc-name a,
.bangvang .title-user,
.bangvang .title-user span,
.dd-loto-widget tr td,
.dd-loto-widget tr td .red,
.loto-tructiep tr td,
.loto-tructiep tr td .red,
.s16,
.thkqtt tbody tr td span,
.vl-box .title-vl a,
p.helper,
p.helper span {
  font-size: 16px;
}

.form-group .label {
  margin-bottom: 5px;
  font-weight: 700;
}

button.form-control {
  background: #74aa19;
  border: 1px solid #628b1c;
  border-radius: 3px;
  cursor: pointer;
}

.box-search-sqk .bdn,
select.form-control {
  height: 37px;
}

.form-control {
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  padding: 10px 0;
}

.btn,
.errorPanel {
  border: 1px solid transparent;
}

button.button-submit {
  height: 38px;
  width: 116px;
  color: #fff;
  font-weight: 700;
}

.col8 {
  width: 8%;
}

#center .result-box table tr td:first-child,
.col10,
.modal-body .result-box .first-col,
.widget-skq-loto .dau-loto {
  width: 10%;
}

.col25 {
  width: 23%;
  margin-right: 2%;
}

.col31 {
  width: 31%;
}

.col32 {
  width: 32%;
}

.col35 {
  width: 35%;
}

.col38 {
  width: 38%;
}

.col40 {
  width: 40%;
}

.col46 {
  width: 46%;
}

.col51 {
  width: 51%;
}

.col52 {
  width: 52%;
}

.col45 {
  width: 45%;
}

.col48 {
  width: 48%;
}

.col75 {
  width: 75%;
}

.col73 {
  width: 73%;
}

.col74 {
  width: 74%;
}

.col24 {
  width: 24%;
}

.col15,
.table-xemloto tbody tr td:first-child,
.w15 {
  width: 15%;
}

#center .vl-box table tr td:first-child,
.col18 {
  width: 18%;
}

.col19 {
  width: 19%;
}

.col-xs-5,
.col20 {
  width: 20%;
}

.col34 {
  width: 34%;
}

.col60,
.loto-input .loto-member {
  width: 60%;
}

.r900,
.widget-skq-loto table tr td.dd-stt {
  color: #900;
}

.rc00 {
  color: #c00;
}

.btn {
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border-radius: 4px;
}

.btn.btn-small {
  padding: 3px 7px;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn.btn-success {
  background: #40bf40;
  text-shadow: none;
  color: #fff;
  filter: none;
}

#___plusone_0,
#___plusone_0 iframe,
.daterangepicker .ranges .input-mini {
  width: 60px !important;
}

.table-condensed > tbody > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > thead > tr > th {
  padding: 2px;
}

.box-table {
  margin-bottom: 10px;
  position: relative;
  min-height: 100px;
}

.box-table .dauso {
  width: 45px;
  height: 45px;
  background: #e3e9f4;
  border: 1px solid #a1b1cf;
  font-weight: 700;
  color: #2d4c87;
  line-height: 46px;
  text-align: center;
}

.box-capso .capso,
.box-capso .solan {
  width: 45px;
  height: 45px;
  line-height: 45px;
  font-weight: 700;
  float: left;
  text-align: center;
}

.box-table .number {
  width: 45px;
  height: 45px;
  text-align: center;
  color: #464646;
  padding: 2px 0;
}

#offcanvas-menu .dropdown[active],
.box-table .number span,
.bv-desktop,
.live-header,
.menu-single,
.message-desktop,
.nav > li,
.nav > li > a,
.table-tructiep .table tr td span,
[id^='tab']:checked ~ [id^='tab-content'] {
  display: block;
}

.box-table .number .num {
  font-size: 20px;
  color: #e80000;
  font-weight: 700;
}

.box-table .none {
  width: 45px;
  height: 45px;
  background: #a09f9f;
  border: none;
}

.box-capso {
  float: left;
  width: 20%;
}

.box-capso .capso {
  border: 1px solid #a4b3d0;
  background: #e3e9f4;
  font-size: 13px;
  color: #2d4c87;
}

.box-capso .solan {
  border: 1px solid #e3e3e3;
  border-left: none;
  background: #fcfaf4;
  font-size: 12px;
  color: #464646;
}

.article .article-content table,
.contact-content input,
.contact-content textarea {
  width: 100% !important;
}

.contact-content .content-label {
  height: 100px;
}

.news-item:first-child {
  border-top: none;
  margin-top: 0;
  padding-top: 0;
}

.news-item {
  display: inline-block;
  clear: both;
  width: 100%;
  border-top: 1px solid #e5e5e5;
  margin-top: 10px;
  padding-top: 10px;
}

.news-item .thumbnail {
  width: 125px;
  float: left;
  margin-right: 10px;
}

.news-item a.img img {
  width: 100%;
  border: none;
  height: 100px;
}

.news-item .title {
  padding: 0;
  font: 400 18px Arial;
  margin-bottom: 7px;
}

.news-item .title a {
  font-size: 15px;
  color: #222;
  font-weight: 700;
  line-height: 20px;
}

.breadcrumb,
.breadcrumb h2,
.du-doan h2,
.table-extend tr td.cbs {
  font-weight: 400;
}

.news-item .date {
  font-size: 14px;
  color: #555;
  padding-bottom: 10px;
}

.news-item .des {
  font-size: 14px;
  line-height: 1.4;
  color: #4f4f4f;
}

#left-article {
  width: 630px;
  float: left;
  min-height: 200px;
}

#right-article {
  width: 330px;
  float: right;
}

.breadcrumb {
  margin: 5px 0 10px 5px;
  font-size: 14px;
  letter-spacing: -0.3px;
  word-spacing: 0.2px;
}

.breadcrumb > li {
  display: -webkit-box;
  overflow: hidden;
}

.breadcrumb > li + li:before {
  padding: 0 5px;
  color: #ccc;
  content: '/\00a0';
}

.article-title h1 {
  font-size: 22px;
  font-weight: 700;
  color: #222;
}

.article .content h2,
.article .content h2 strong,
.article .content h3 strong,
.article .content p strong {
  font-size: 18px;
  color: #222;
}

.article .content,
.article .short-description,
.article .short-description h2 {
  line-height: 23px;
}

.article .author {
  display: block;
  font-style: italic;
  margin-bottom: 5px;
  float: left;
}

.article a span,
.content-sonong h3,
.content-sonong h4,
.content-sonong span,
.s18,
.widget-skq table tr td {
  font-size: 18px;
}

.article .article-content .short-description,
.article .short-description h2 {
  font-size: 18px;
  line-height: 160%;
  margin-bottom: 15px;
}

.article .article-content img,
.info-result img {
  max-width: 100%;
}

.article .article-content p img {
  display: block;
  margin: auto;
}

.article .content p,
.article .content ul li {
  font-size: 18px;
  line-height: 160%;
  font-weight: 400;
  margin-bottom: 15px;
}

section.related h3 {
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 18px;
}

section.related ul {
  margin: 0 0 15px 16px;
  font-size: 15px;
  line-height: 20px;
}

section.related ul li {
  list-style: initial;
  margin-bottom: 8px;
}

section.related ul li a {
  color: #076db6;
  font-size: 16px;
  line-height: 160%;
}

ul.more-news {
  display: block;
  clear: both;
  margin-top: -1px;
  overflow-y: auto;
  overflow-x: hidden;
}

ul.more-news li {
  display: block;
  overflow: visible;
}

.lotto-result .result-max4d li,
.table-extend tbody tr:first-child td,
.thongkedauduoi tr td:first-child,
ul.more-news li a h3 {
  font-weight: 500;
}

ul.more-news li a.actnews {
  background: #eee;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
}

ul.more-news li a.actnews:before {
  border-color: rgba(218, 218, 218, 0);
  border-right-color: #eee;
  border-width: 14px;
  position: absolute;
  top: 30%;
  left: -27px;
  z-index: 9;
}

ul.more-news li a img {
  float: left;
  width: 100px;
  height: 60px;
  margin: 3px 10px 10px 5px;
}

.tags ul {
  margin: 0;
  padding: 0 0 0 7px;
}

.tags ul li {
  display: inline-block;
  margin: 0 10px 5px 0;
}

.tags ul li a {
  background-color: #767676;
  border-radius: 0 2px 2px 0;
  color: #fff;
  display: inline-block;
  font-size: 11px;
  font-weight: 400;
  margin: 0;
  padding: 3px 7px;
  position: relative;
  text-transform: uppercase;
}

.tags ul li a:before {
  border-top: 10px solid transparent;
  border-right: 8px solid #767676;
  border-bottom: 9px solid transparent;
  content: '';
  height: 0;
  position: absolute;
  top: 0;
  left: -8px;
  width: 0;
}

.tags ul li a:after {
  background-color: #fff;
  border-radius: 50%;
  content: '';
  height: 4px;
  position: absolute;
  top: 8px;
  left: -2px;
  width: 4px;
}

.pagination {
  float: right;
  width: 100%;
}

.pagination .textpage {
  float: left;
  line-height: 25px;
  margin-right: 10px;
  width: 40%;
}

.pagination ul li {
  float: left;
  background: #fff;
  border: 1px solid #d6d6d6;
  margin-right: 2px;
  line-height: 25px;
  text-align: center;
  border-radius: 3px;
}

.pagination ul li.active,
.pagination ul li:hover {
  background: #2d4c87;
  border: 1px solid #355aa0;
}

.pagination ul li.disabled span {
  color: #414141;
}

.pagination ul li a,
.pagination ul li span {
  font-size: 12px;
  font-weight: 700;
  padding: 8px 10px;
}

.pagination ul li:hover {
  color: #fff;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.loto-input {
  border: 2px solid #e52e04;
  min-height: 200px;
  margin: 10px 0;
  padding: 10px;
  border-radius: 2px;
}

.game-hot .game-item:nth-child(2n),
.loto-input .btnPlay,
.tag_games .game-item:nth-child(4n) {
  margin-right: 0 !important;
}

.loto-input .btnPlay .btn-play {
  width: 150px;
  color: #fff;
  background: #e52e04;
}

.full-form .form-group .btn-login,
.full-form .form-group .btn-register {
  width: 20%;
  color: #fff;
  font-weight: 700;
}

.loto-input .btn-nap-xu {
  background: #e72d04;
  width: 60px;
  line-height: 23px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  border-radius: 3px;
  display: inline-block;
}

.listTime,
.loto-input .label-you-want,
.sidebar-menu .logo p,
.xoso360-social .button.last {
  margin: 10px 0;
}

.loto-input .select-loto .loto-xien {
  background: #fafafa;
  border: 1px solid #dfdfdf;
  min-height: 72px;
  margin-right: 5px;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
}

.loto-input .select-loto .loto-xien.active {
  border: 1px solid #e52e04;
}

.loto-input .select-loto .loto-xien .loto-type {
  font-weight: 700;
  margin-bottom: 3px;
}

.loto-input .select-loto .loto-xien .proportion {
  color: #999;
}

.loto-input .select-loto .loto-xien div.rCheck {
  display: block;
  min-height: 20px;
  vertical-align: middle;
  margin-top: 3px;
}

.loto-input .select-loto .loto-xien div.rCheck label {
  margin-top: 3px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  font-size: 13px;
  margin-bottom: 6px;
  color: #777a80;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.loto-input .select-loto .loto-xien div.rCheck label:before {
  content: '';
  display: inline-block;
  width: 17px;
  height: 17px;
  background-color: #fff;
  border: 1px solid #c2c6cb;
  border-radius: 99px;
}

.loto-input
  .select-loto
  .loto-xien
  div.rCheck
  input[type='radio']:checked
  + label:before {
  background: red;
  border: 1px solid red;
  transform: scale(0.63);
}

.loto-input .input .label {
  margin-bottom: 5px;
  font-size: 13px;
}

.irs {
  margin-top: -12px;
}

.loto-action {
  background: #f8f8f8;
  border: 1px solid #d9d9d9;
  border-top: none;
  min-height: 45px;
  line-height: 45px;
  padding: 0 15px;
  border-radius: 0 0 2px 2px;
  margin-bottom: 20px;
}

.loto-action button {
  margin-top: 6px;
  border-radius: 3px;
  color: #fff;
  background: #e52e04;
}

.loto_today {
  border-radius: 2px;
  background: #f8f8f8;
  border: 1px solid #d9d9d9;
  min-height: 50px;
  margin-bottom: 15px;
}

.loto_today .bet {
  float: left;
  border: 1px solid #eadac1;
  background: #f9ecd7;
  margin: 5px;
  padding: 4px;
  font-size: 12px;
  color: #333;
  position: relative;
  z-index: 0;
  width: 98px;
  height: 58px;
  text-align: center;
  cursor: pointer;
}

.loto_today .info-today {
  width: 30%;
  float: left;
}

.loto_today .landing-thongke {
  width: 68%;
  float: right;
}

.loto_today .landing-thongke ul li a {
  margin-left: 20px;
  color: #2d4c87;
}

.loto-history table {
  width: 100%;
  margin-bottom: 15px;
}

.loto-history table tr,
.loto-nourish table tr {
  height: 35px;
  text-align: center;
}

.loto-history table tr td,
.loto-history table tr:first-child td,
.loto-nourish table tr td {
  border: 1px solid #d9d9d9;
}

.loto-history table tr:first-child,
.loto-history table tr:last-child,
.loto-nourish table tr:first-child,
.table thead {
  background: #fafafa;
}

.loto-history table tr td[rowspan] {
  text-align: left;
  padding-left: 20px;
}

.loto-history table tr td[rowspan] span.day {
  color: #38578e;
  font-weight: 700;
}

.loto-history table tr:last-child td {
  border-left: none;
  border-right: none;
}

.loto-history table tr:last-child td:first-child {
  border-left: 1px solid #d9d9d9;
}

.loto-history table tr:last-child td:last-child {
  border-right: 1px solid #d9d9d9;
}

.loto-history table tr td.trung {
  background: #f2f6e8;
}

.loto-history table tr td.tach {
  background: #ffe5e6;
}

#offcanvas-menu .item:last-child,
#offcanvas-menu ul li,
.loto-nourish {
  margin-bottom: 25px;
}

.login-page {
  text-align: center;
  margin: 0 auto;
  padding: 10px;
}

.errorPanel,
.successPanel {
  padding: 15px;
  margin-bottom: 10px;
}

.validation-form .text-danger {
  float: left;
  padding: 5px;
  margin-left: 5px;
}

.full-form .form-group {
  width: 100%;
  text-align: left;
}

.full-form .form-group .btn-login {
  background: #e62e04;
  border: 1px solid red;
}

.errorPanel {
  background-color: #f2dede;
  -moz-border-radius: 5px;
  -khtml-border-radius: 5px;
}

.errorPanel .errors {
  display: block;
  color: #a94442;
}

.successPanel {
  background-color: #aad178;
  border: 1px solid transparent;
  color: #fff;
}

.login-page .social-group {
  text-align: center;
  width: 100%;
  margin: 20px auto;
}

.login-page .social-group .login-title {
  width: 90%;
  margin: auto auto 36px;
  border-bottom: 1px solid #ddd;
  height: 7px;
}

.login-page .social-group .login-title span {
  margin-top: 10px;
  background: #fff;
  padding: 0 11px;
  color: #646472;
}

.login-page .social-group .social-button {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -khtml-border-radius: 3px;
  border-radius: 3px;
  background: #fff;
  padding: 10px;
  color: #fff;
  display: inline-block;
  text-align: center;
  width: 75px;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none !important;
}

#center .breadcrumb,
.main-tk-sc .header-title .title-text {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
}

.login-page .social-group .fb-button {
  background: #43609c;
}

.login-page .social-group .google-button {
  background: #d23f2a;
}

.login-page .social-group .twitter-button {
  background: #0084b4;
}

.login-page .social-group .yahoo-button {
  background: #640f6c;
}

.note .icon-note {
  width: 17px;
  height: 17px;
  border-radius: 99%;
  font-weight: 700;
  font-size: 16px;
  border: 2px solid red;
}

.message-loto {
  margin: 18px 0 15px 10px;
  color: #e52e04;
  font-size: 14px;
  float: left;
}

.table tr th {
  border: 1px solid #d9d9d9;
  text-align: center !important;
  font-weight: 700;
}

.table tr td {
  border: 1px solid #e1e1e1;
}

.list-game .game-item,
.related-games .game-item {
  width: 143px;
  height: 150px;
  float: left;
  padding: 10px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.list-game .game-item img,
.related-games .game-item img {
  width: 100%;
  height: 138px;
  border-radius: 9%;
}

.list-game .game-item .title,
.related-games .game-item .title {
  margin-top: 10px;
  text-align: left;
}

.list-game .game-item .title a,
.related-games .game-item .title a {
  color: #e62e04;
  font-size: 13px;
}

.game-detail {
  border: 1px solid #ddd;
  min-height: 500px;
  margin-bottom: 15px;
  padding: 10px;
}

.action,
.table-tructiep,
div#wwo_outer {
  margin-bottom: 10px;
}

.game-detail .title {
  font-size: 16px;
  text-transform: uppercase;
  color: #e62e04;
  font-weight: 700;
  margin-bottom: 10px;
}

.form-login .form-group input.form-control {
  height: 30px;
  width: 100%;
  margin-bottom: 15px;
  padding: 6px 12px;
}

.table-tructiep {
  border: 1px solid #e1e1e1;
  position: relative;
}

.table-tructiep .title {
  height: 37px;
  color: #900;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  background: #e8e8e8;
  line-height: 37px;
  text-align: center;
}

.table-tructiep .caption-tructiep {
  font-size: 15px;
  color: #2d4c87;
  font-weight: 700;
  text-align: center;
  margin: 15px 0 10px;
}

.table-tructiep .table thead tr th {
  font-weight: 700;
  color: #1f10e2;
  font-size: 14px;
  border-right: none;
  font-family: Tahoma, Geneva, sans-serif;
}

.table-tructiep .table thead tr th a {
  font-weight: 700;
  color: #e62e04;
  font-size: 14px;
  border-right: none;
  text-decoration: underline;
}

.loto-tructiep tr th:first-child,
.table-tructiep .table thead tr th:first-child {
  width: 10%;
  font-weight: 400;
  color: #414141;
}

.tk-nhanh-40 .table tr:nth-child(2n) {
  background: 0 0;
}

.dd-loto-widget table tr,
.table tr {
  height: 35px !important;
}

.table-tructiep .table tr td .number {
  line-height: 28px;
  height: 28px;
  font-size: 23px;
  font-weight: 700;
  padding: 4px 5px;
  text-align: center;
  color: #333;
}

.table-tructiep .table tr td mark {
  font-size: 22px;
  font-weight: 500;
  background: #fb9b34;
  color: #fff;
  border-radius: 4px;
  padding: 0 1px;
}

.result-box .vl-max4d table tr td.number,
.table-tructiep .table tr.gdb td .number {
  font-size: 24px;
}

.loto-tructiep {
  min-height: 300px;
}

.loto-tructiep tr td:first-child {
  width: 10%;
  padding: 0;
  text-align: center;
}

.loto-tructiep tr th {
  border: 1px solid #d9d9d9;
  font-weight: 700;
  text-align: center;
  height: 35px;
  line-height: 35px;
  color: #1f10e2;
  font-size: 14px;
  font-family: Tahoma, Geneva, sans-serif;
  background: #fafafa;
}

.loto-tructiep tr td {
  text-align: left;
  padding-left: 5px;
  font-weight: 700;
}

.border-red {
  border: 2px solid #e62e04;
  position: relative;
}

.action {
  height: 50px;
  background: #f8f8f8;
  border: 1px solid #d9d9d9;
  border-radius: 0 0 3px 3px;
  border-top: none;
}

.action .btn-default,
.btn-inline-default {
  background: #74aa19;
  color: #fff;
}

.action-link .icon-note {
  margin: 5px 10px 5px 5px;
}

.action ul li {
  float: left;
  line-height: 48px;
  color: #777;
}

.action-link ul li {
  line-height: 27px;
  float: left;
}

.action ul li:after,
.action-link ul li:after {
  content: '\00a0-\00a0';
}

.action ul li:last-child:after,
.action-link ul li:last-child:after {
  content: '' !important;
}

.action .btn-default {
  margin: 10px 15px 0 0;
  border: 1px solid #628b1c;
}

.cungquayxoso .col2,
.cungquayxoso .col4 {
  border-right: 1px solid #d9d9d9;
  margin-top: -10px;
  margin-bottom: -10px;
  font-size: 18px;
  text-align: center;
}

.btn-inline-default.active {
  font-weight: 700;
  background: #2d4c87;
}

.header-title-blue {
  background: #2d4c87;
  height: 37px;
}

.result-table {
  background: #e3e3e3;
}

.result-table thead tr th,
.thkqtt tbody tr:first-child td {
  font-weight: 700;
  color: #900;
}

.result-table tbody {
  background: #fff;
}

.result-table tbody tr td .number {
  font-size: 20px;
  color: #00f;
  font-weight: 700;
}

.result-table tbody tr td .number-full {
  font-size: 20px;
  font-weight: 700;
  margin: 3px 0;
}

.result-table tbody tr td .date {
  color: #424242;
  font-size: 15px;
}

.text-danger {
  color: #e83905;
}

.text-danger a {
  color: #e83905;
  font-weight: 700;
}

.text-success {
  color: #74aa19;
}

.text-success a {
  color: #74aa19;
  font-weight: 700;
}

.text-silver {
  color: #ccccdc;
}

.text-silver a {
  color: #ccccdc;
  font-weight: 700;
}

.thongkedauduoi {
  margin-bottom: 30px;
}

.checkbox label {
  display: inline;
  cursor: pointer;
  position: relative;
  padding-left: 16px;
  margin-right: 18px;
  background: #fff;
  border-radius: 2px;
}

.checkbox input[type='checkbox']:checked + label:before {
  border: 1px solid red !important;
}

.checkbox input[type='checkbox']:checked + label:after {
  opacity: 1;
}

.checkbox label:after {
  opacity: 0;
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 2px;
  background: #e62e04;
  left: 3px;
  top: 3px;
}

.checkbox-list ul li {
  float: left;
  margin: 0 -5px 10px 0;
}

.checkbox label::before {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 10px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
  border: 1px solid #d3d3d3;
}

.fast-select {
  border: 1px solid #dfdfdf;
  height: 220px;
  width: 100%;
  border-radius: 2px;
}

.fast-select option {
  border-bottom: 1px solid #dfdfdf;
  padding: 10px 15px;
}

.table-extend tbody {
  display: block;
  overflow-x: auto;
  width: 704px;
}

.table-extend .black {
  background: grey;
}

.lightbox-content .table-extend tbody tr td,
.table-extend tr td {
  padding: 3px !important;
}

.expant-title {
  background: #74aa19;
  width: auto;
  float: right;
  cursor: pointer;
  padding-right: 10px;
}

.scrollTop,
.scrollTop div {
  height: 20px;
}

.scrollTop,
.table-extend .table tbody {
  width: 839px;
  border: 0 RED;
  overflow-x: scroll;
  overflow-y: hidden;
}

.lightbox-gray {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1111;
  background-color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  text-align: center;
  display: none;
}

.lightbox-content {
  width: 100%;
  position: absolute;
  z-index: 1112;
  display: none;
  background-color: #fff !important;
  height: 100% !important;
}

.lightbox-content .table-extend {
  width: 100%;
  position: relative;
}

.lightbox-content a.closeLightbox {
  position: absolute;
  width: 50px;
  color: #fff;
  background-color: red;
  padding: 3px 5px;
  top: 0;
  right: 0;
  text-decoration: none;
  z-index: 99999999;
}

.radio label:after,
.radio label:before {
  display: inline-block;
  position: absolute;
  width: 13px;
  height: 13px;
}

.digits-form .radio label,
.radio label {
  padding-left: 20px;
}

input[type='radio'] {
  margin: 4px 0 0;
  line-height: normal;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.radio label {
  position: relative;
  margin-right: 15px;
  font-size: 13px;
  color: #343434;
  display: inline;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
}

.radio input[type='radio']:checked + label:before,
.radio + .radio input[type='radio']:checked {
  border: 1px solid #e62e04;
}

.radio label:before {
  content: '';
  left: 0;
  border: 1px solid #ccc;
  border-radius: 50%;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out;
  transition: border 0.15s ease-in-out;
}

.radio input[type='radio']:checked + label:after {
  -o-transform: scale(0.63);
  -ms-transform: scale(0.63);
  -moz-transform: scale(0.63);
  -webkit-transform: scale(0.63);
  transform: scale(0.63);
  line-height: 18px;
  -o-transition: transform 0.1s cubic-bezier(0.455, 0.03, 0.215, 1.33);
  -ms-transition: transform 0.1s cubic-bezier(0.455, 0.03, 0.215, 1.33);
  -moz-transition: transform 0.1s cubic-bezier(0.455, 0.03, 0.215, 1.33);
  -webkit-transition: transform 0.1s cubic-bezier(0.455, 0.03, 0.215, 1.33);
  transition: transform 0.1s cubic-bezier(0.455, 0.03, 0.215, 1.33);
  background-color: #d9534f;
}

.radio label:after {
  content: ' ';
  left: 1px;
  top: 1px;
  border-radius: 50%;
  background-color: #555;
  -webkit-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}

.radio-border {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #dfdfdf;
}

.thkqtt tbody tr td {
  padding: 5px !important;
  font-size: 16px;
}

.thkqtt tbody tr td:first-child {
  width: 35px !important;
  color: #1f10e2;
  font-weight: 400;
  font-size: 14px;
}

.full-infor .tienich-item:first-child,
.full-infor .tienich-item:nth-child(4n) {
  margin-left: 0 !important;
}

.full-infor .tienich-item {
  width: 92px !important;
  margin-left: 7px !important;
}

.tvshow-item {
  width: 20%;
  float: left;
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
  margin: 10px 10px 0 0;
}

.cungquayxoso .col4 {
  width: 24.8%;
  float: left;
  line-height: 36px;
}

.cungquayxoso .col2 {
  width: 49.69%;
  float: left;
  line-height: 40px;
}

.tamgiac {
  text-align: center;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 700;
}

.fast-select:focus {
  outline: 0;
  border: 1px solid #ddd;
}

.checked-list ul {
  width: 14%;
  float: left;
}

.checked-list ul li {
  margin: 0 0 10px;
}

#right-big {
  width: 80%;
  min-height: 100px;
  float: right;
}

.profile .form-control {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
}

#popup_id {
  display: block;
  height: 100%;
  position: absolute !important;
}

.modal-dialog {
  width: 600px;
  z-index: 9999999999;
}

.modal-content {
  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}

.modal-header {
  min-height: 16.43px;
  padding: 10px;
  border-bottom: 1px solid #e5e5e5;
}

.modal {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2050;
  display: none;
  overflow: hidden;
  outline: 0;
}

#loading,
.listTime ul li a,
div#wwo_outer {
  border: 1px solid #ccc;
}

.modal-header .close {
  font-size: 40px;
  margin-top: -12px;
}

button.close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0;
  border: 0;
}

.close {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
}

.modal .modal-footer {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-top: 0;
}

.modal-footer {
  text-align: right;
}

#loading,
.btn-agree,
.play-now {
  text-align: center;
}

#left-widget {
  width: 49%;
  float: left;
}

#right-widget {
  width: 49%;
  float: right;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2040;
  background-color: #000;
  opacity: 0.7;
}

#loading {
  width: 240px;
  height: 120px;
  background: #fff;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  font-weight: 700;
}

div#wwo_outer {
  padding: 10px;
}

div#wwo_outer h3 a {
  color: #e62e04;
  font-size: 18px;
}

.xoso360-social .button {
  float: left;
  margin: 10px 10px 10px 0;
}

.orange_bg,
.tr_hover:hover {
  background: #f39c25;
}

.wrapperCinema {
  border: 1px solid #ddd;
  margin-bottom: 15px;
}

.titleCinema {
  font-size: 18px;
  margin-bottom: 15px;
}

.itemCinema,
.padTLR15 {
  padding: 15px 15px 0;
}

.listTime ul li {
  float: left;
  margin-right: 5px;
  margin-bottom: 25px;
}

.listTime ul li a {
  padding: 10px;
}

.action .btn-inline-default {
  margin: 10px 5px 0 0;
}

.centerTable {
  display: table;
  margin: auto;
}

.tablePadding10 tr td {
  padding: 10px !important;
}

.full-form .explain {
  margin: 5px 60px 5px 0;
  color: #969696;
  font-size: 12px;
}

.btn-agree {
  margin: 0 10px 10px 0;
  background: #74aa19;
  display: inline-block;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #fff;
}

.top-social-ketquavn ul li {
  float: left;
  margin-right: 5px;
  height: 20px;
  line-height: 23px;
}

.top-social-ketquavn ul:after {
  content: '';
  clear: both;
}

.top-social-ketquavn ul li a.btn-set-homepage {
  text-transform: none;
  text-transform: uppercase;
  padding: 0 6px;
  border-radius: 2px;
  background: #55acee;
  color: #fff;
  font-size: 10px;
  font-weight: 700;
  box-shadow: 0 0 3px #ccc;
  display: block;
  text-indent: 0;
  height: 19px;
  line-height: 20px;
}

.list-menu ul {
  margin-left: 50px;
}

.list-menu ul li {
  list-style: initial;
  margin: 5px 0;
}

.list-menu ul li a {
  font-size: 16px;
  color: #2d4c87;
}

#bdbt td,
.Zebra_DatePicker td,
.box-response .table tr td,
.btn-group-skq a,
.number-date-range span,
.tooltip,
[data-tooltip] {
  cursor: pointer;
}

.lightbox-content .result-table tbody tr td {
  padding: 4px !important;
  cursor: pointer;
}

.info-source {
  margin-top: 10px;
  font-style: italic;
  font-size: 13px;
}

.kq-table-hover td.info,
.kq-table-hover tr.info td,
.kq-table-hover tr.info th,
.lich-quay-xo-so tbody tr:nth-child(odd) {
  background: #f4f2e6;
}

.lich-quay-xo-so tbody tr td {
  padding: 10px 0 10px 10px;
}

.lich-quay-xo-so tbody tr td:first-child {
  font-size: 13px;
  color: #2d4c87;
  font-weight: 700;
  padding: 0;
  text-align: center;
}

.lich-quay-xo-so tbody tr td p,
.table-xemloto tbody tr td {
  line-height: 25px;
}

.facebook-widget .widget-content {
  padding: 4px;
}

#tksc-list {
  border-top: 1px solid #cdcdcd;
  margin-top: 10px;
}

.list-thongke .tksc-item {
  border: 1px solid #cdcdcd;
  width: 276px;
  padding: 8px 5px;
  height: 30px;
  line-height: 30px;
}

.list-thongke .tksc-item a {
  float: left;
  font-size: 15px;
}

#tksc-list .tksc-item:nth-child(2n) {
  border-left: none;
  width: 244px;
}

#tksc-list .tksc-item-2 {
  border: 1px solid #cdcdcd;
  width: 158px;
  float: left;
  padding: 5px;
  margin-bottom: 5px;
  height: 75px;
  border-top: none;
}

#tksc-list .tksc-item-2:nth-child(2n) {
  border-left: none;
  border-right: none;
  width: 160px;
}

.embed-links {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #cdcdcd;
}

.embed-links a {
  padding: 5px 9px;
}

.embed-links a i {
  font-size: 17px;
  margin-right: 3px;
}

.tags-footer {
  width: 1000px;
  margin: 0 auto;
}

.tags-footer ul li {
  padding: 3px 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  display: inline-block;
  background: #757575;
}

.tags-footer ul li a {
  color: #fff;
  font-size: 12px;
}

.game-hot .game-item {
  float: left;
  width: 44%;
  margin: 10px;
}

.game-hot .game-item a img {
  width: 100%;
  height: 110px;
  margin-bottom: 5px;
}

.game-hot .game-item .title a {
  color: #e62e04;
  font-size: 12px;
}

#xs360-widget-embed {
  margin-top: 27px;
}

.related-games h2 {
  font-size: 15px;
  text-transform: uppercase;
  border-left: 4px solid red;
  padding-left: 7px;
}

.play-now {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #f28007;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  display: inline-block;
  padding: 10px 5px;
  width: 25%;
}

.table-extend tbody tr td.black:last-child,
.table-extend tr td {
  border: 1px solid #666 !important;
}

.print-kqxs-region {
  position: absolute;
  top: -1px;
  right: 20px;
}

#update-social-form .error {
  margin-left: 300px;
  margin-bottom: 5px;
}

.table-extend tr {
  height: inherit !important;
}

.table-extend tr td {
  font-weight: 700;
  height: 100%;
}

.table-extend tbody tr td:first-child,
.table-extend tbody tr:first-child td {
  font-weight: 400;
  border: 1px solid #d9d9d9 !important;
  font-size: 15px;
}

.lightbox-content .result-table {
  padding: 0 !important;
}

.tk-loto table tr td {
  padding: 0 5px !important;
  box-sizing: border-box;
}

.loto-daigia tr td:nth-child(2),
.loto-tilethang tr td:nth-child(2),
.loto-toptuan tr td:nth-child(2) {
  text-align: left;
  padding-left: 20px !important;
}

.lightbox-content .form-input.tansuat {
  width: 675px;
  margin-top: 10px;
  margin-left: 15px;
}

.lightbox-content .form-input.tansuat .btn-tansuat {
  background: #74aa19;
  color: #fff;
  margin-bottom: 10px;
  margin-top: 10px;
}

.tansuat-note {
  margin-top: 10px;
  line-height: 20px;
}

#bdbt td:hover,
.orange_bg2 {
  background: #ffeaa6;
}

#bdbt .weekend {
  background: #e9f4fc;
}

.top-1 {
  color: #f93 !important;
}

.top-2 {
  color: green !important;
}

.top-3 {
  color: #deb887 !important;
}

.g-recaptcha {
  float: left;
  margin-left: 10px;
  margin-bottom: 10px;
}

.bangvang tr td {
  padding-left: 5px;
  padding-right: 5px;
  text-align: left;
}

.Zebra_DatePicker .dp_daypicker th,
.Zebra_DatePicker .dp_header .dp_caption,
.bangvang thead tr th,
.item-name a,
.menu-diw .nav a,
.result-box .draw-info .mega-draw-no,
.result-box .draw-info .power-draw-no,
.table-prediction tr td.name {
  font-weight: 700;
}

.bangvang tr td:nth-child(2) ul {
  margin-top: 10px;
  margin-bottom: 10px;
}

.bangvang tr td:nth-child(2) ul li {
  list-style: initial;
  margin-left: 15%;
  padding-bottom: 5px;
}

.tooltip:after,
.tooltip:before,
[data-tooltip]:after,
[data-tooltip]:before {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -moz-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  pointer-events: none;
}

.tooltip:focus:after,
.tooltip:focus:before,
.tooltip:hover:after,
.tooltip:hover:before,
[data-tooltip]:focus:after,
[data-tooltip]:focus:before,
[data-tooltip]:hover:after,
[data-tooltip]:hover:before {
  visibility: visible;
  opacity: 1;
}

.tooltip:before,
[data-tooltip]:before {
  z-index: 1001;
  border: 6px solid transparent;
  background: 0 0;
  content: '';
}

.tooltip:after,
[data-tooltip]:after {
  z-index: 1000;
  padding: 8px;
  width: 160px;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  font-size: 12px;
  line-height: 1.2;
  text-align: left;
}

.tooltip-top:after,
.tooltip-top:before,
.tooltip:after,
.tooltip:before,
[data-tooltip]:after,
[data-tooltip]:before {
  bottom: 100%;
  left: 50%;
}

.tooltip-top:before,
.tooltip:before,
[data-tooltip]:before {
  margin-left: -6px;
  margin-bottom: -12px;
  border-top-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-bottom:before,
.tooltip-left:before,
.tooltip-right:before {
  border-top-color: transparent;
  margin-bottom: 0;
}

.tooltip-top:after,
.tooltip:after,
[data-tooltip]:after {
  margin-left: -30px;
}

.tooltip-top:focus:after,
.tooltip-top:focus:before,
.tooltip-top:hover:after,
.tooltip-top:hover:before,
.tooltip:focus:after,
.tooltip:focus:before,
.tooltip:hover:after,
.tooltip:hover:before,
[data-tooltip]:focus:after,
[data-tooltip]:focus:before,
[data-tooltip]:hover:after,
[data-tooltip]:hover:before {
  -webkit-transform: translateY(-12px);
  -moz-transform: translateY(-12px);
  transform: translateY(-12px);
}

.tooltip-left:after,
.tooltip-left:before {
  right: 100%;
  bottom: 50%;
  left: auto;
}

.tooltip-left:before {
  margin-left: 0;
  margin-right: -12px;
  border-left-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-left:focus:after,
.tooltip-left:focus:before,
.tooltip-left:hover:after,
.tooltip-left:hover:before {
  -webkit-transform: translateX(-12px);
  -moz-transform: translateX(-12px);
  transform: translateX(-12px);
}

.tooltip-bottom:after,
.tooltip-bottom:before {
  top: 100%;
  bottom: auto;
  left: 50%;
}

.tooltip-bottom:before {
  margin-top: -12px;
  border-bottom-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-bottom:focus:after,
.tooltip-bottom:focus:before,
.tooltip-bottom:hover:after,
.tooltip-bottom:hover:before {
  -webkit-transform: translateY(12px);
  -moz-transform: translateY(12px);
  transform: translateY(12px);
}

.tooltip-right:after,
.tooltip-right:before {
  bottom: 50%;
  left: 100%;
}

.tooltip-right:before {
  margin-left: -12px;
  border-right-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-right:focus:after,
.tooltip-right:focus:before,
.tooltip-right:hover:after,
.tooltip-right:hover:before {
  -webkit-transform: translateX(12px);
  -moz-transform: translateX(12px);
  transform: translateX(12px);
}

.tooltip-left:before,
.tooltip-right:before {
  top: 3px;
}

.tooltip-left:after,
.tooltip-right:after {
  margin-left: 0;
  margin-bottom: -16px;
}

.loto-help-icon {
  position: absolute;
  right: 5px;
  top: 6px;
}

#go-to-top {
  position: fixed;
  right: 0;
  bottom: 40px;
}

.tklltlt {
  padding: 5px 0 5px 15px;
}

.view-numbers table tr td {
  text-align: left;
  padding: 5px;
}

.bangvang-date .Zebra_DatePicker_Icon_Inside {
  left: 132px !important;
}

.sound-kqxs-region {
  position: absolute;
  top: 3px;
  left: 20px;
}

.sound-kqxs {
  position: absolute;
  top: 15px;
  left: 25px;
}

.tabs,
.tabs label {
  position: relative;
}

.sound-kqxs-region:after,
.sound-kqxs:after {
  width: 45px;
}

.print-kqxs-region:after,
.print-kqxs:after {
  width: 90px;
}

.padB15 {
  padding-bottom: 15px;
}

.live-menu-item:nth-child(2n) .live-menu-content p a:after {
  width: 173px;
}

.prediction-title {
  font-size: 15px;
  font-weight: 700;
  color: #1f10e2;
}

.table-prediction tr td {
  text-align: left;
  padding-left: 10px;
}

.sms-box-item {
  border: 2px solid #cdcdcd;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  margin-bottom: 10px;
  padding: 5px;
  width: 236px;
  float: left;
}

.soketqua-container .sms-container {
  width: 486px;
}

.soketqua-container .sms-box-item {
  width: 224px;
}

.app-download {
  padding: 5px;
  border: 1px dashed #cdcdcd;
  margin-bottom: 10px;
}

.app-download .new {
  margin-left: 0;
  margin-bottom: 5px;
}

.download-text {
  width: 230px;
}

.download-text div {
  margin-left: 35px;
}

.tabs-container {
  height: 350px;
  margin-top: 10px;
}

.tabs {
  float: none;
  padding: 0;
  margin: 0 auto;
}

.tabs input[type='radio'],
.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}

[id^='tab']:checked + label {
  background: #2d4c87;
  color: #fff;
  top: 0;
  text-transform: uppercase;
}

.tabs label {
  display: block;
  padding: 5px 10px;
  color: #414141;
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.tab-content {
  display: none;
  width: 100%;
  border: 1px solid #cdcdcd;
  position: absolute;
  top: 30px;
  left: 0;
  box-sizing: border-box;
  -webkit-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

.name-result-max4d,
.result-max4d li .name-result-max4d {
  display: block;
  font-size: 15px;
  text-align: center;
}

#ctg_content .result-box td,
.chatbox,
.kq-table-hover .info {
  border: none;
}

#tab-content1,
#tab-content2 {
  height: 310px;
}

#tab-content1,
.tklotogan .result-table-tkgan {
  overflow: auto;
}

.vl-box .result-header {
  height: auto;
  border-bottom: 1px solid #d1d1d1;
  padding: 5px 0;
}

.jackpot_info {
  font-size: 15px;
  color: #000;
  padding: 10px 0 0;
  text-align: center;
}

.result-box .jackpot-amount-box {
  padding: 10px 15px;
  background: #ed3838;
  border-radius: 20px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  width: 300px;
  margin: 15px auto;
  text-align: center;
}

.result-box .mega645-item,
.result-box .power-item {
  font-size: 24px;
  width: 46px;
  height: 46px;
  line-height: 46px;
  font-weight: 700;
  padding: 10px;
}

.result-box .draw-info {
  text-align: center;
  margin-top: 10px;
}

.result-box .mega645-result {
  text-align: center;
  margin: 15px 0;
}

.result-box .mega645-item {
  border-radius: 100%;
  color: #fff;
  margin: 10px;
}

.result-box .power-item {
  border: 2px solid #dddfe2;
  border-radius: 100%;
  color: #ed3b3b;
  margin: 5px;
}

.result-box .mega645-wins table tr td {
  font-size: 14px;
  text-align: left;
  padding-left: 5px;
}

.result-box .vl-max3d table tr td,
.result-box .vl-max4d table tr td {
  padding-left: 0;
}

.result-box .mega645-wins table tr .circle-num i {
  font-style: normal;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #414141;
  display: inline-block;
  margin: 0 5px 0 0;
}

.result-max4d {
  margin: 0;
  padding: 0;
  text-align: center;
}

.lotto-result .result-max4d li .name-result-max4d,
.name-result-max4d {
  color: #fc000d;
  margin-bottom: 6px;
  font-weight: 500;
}

.result-max4d li {
  border-radius: 5px;
}

.result-max4d .box-result-max4d {
  display: inline-block;
  margin-bottom: 12px;
  margin-top: 7px;
}

.result-max4d li .num-result-max4d {
  color: #ed1d27;
  display: inline-block;
  margin: 0 8px;
}

.result-max4d li .num-result-max4d li {
  color: #2d4c87;
  font-size: 27px;
  border: 3px solid #2d4c87;
  display: inline-block;
  border-radius: 50%;
  width: 29px;
  height: 29px;
  padding: 0;
  margin: 0;
  line-height: 30px;
  text-align: center;
  font-weight: 700;
}

.result-max4d li .num-result-max4d .divide {
  border-radius: 0;
  width: 2px;
  border: none;
  background: #2d4c87;
  height: 24px;
  position: relative;
  top: 4px;
  margin-left: 20px;
  margin-right: 4px;
}

#c_c {
  color: #f22613;
}

.table-tructiep .title-tn {
  height: 37px;
  color: #e62e04;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  line-height: 37px;
  text-align: center;
}

.table-tructiep .table tr td.first-col {
  font-weight: 400 !important;
  border-left: none;
}

.title-power {
  margin-bottom: 7px;
}

.power-red {
  border: 2px solid #fa8d29 !important;
  background: #fa8d29 !important;
}

.power-line-jack {
  height: 10px !important;
  border-radius: 0 !important;
  border: 1px solid #aeaeb1 !important;
  margin: 0 4px 0 0;
  width: 0 !important;
}

.mega-col {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.bang-loto .clred,
.mega-number {
  font-size: 20px !important;
}

.mega-number-db,
.vl-max4d .mega-number {
  font-size: 22px !important;
}

.line-power {
  height: 42px;
  vertical-align: bottom !important;
  border-radius: 0;
  border: 1px solid #aeaeb1;
  display: inline-block;
}

.img-vietlott {
  margin-top: 8px;
}

ul.tk-sc-menu li img {
  padding-left: 10px;
  width: 18px;
}

body,
html {
  width: 100%;
  margin: 0;
  padding: 0;
}

p.helper {
  margin-bottom: 16px !important;
}

.box-search-sqk .number-date-range input {
  margin-right: 0;
  width: 90px;
}

.box-search-sqk .form-control {
  padding: 8px 0 8px 10px;
}

.header-mobile {
  position: relative;
  margin-bottom: -5px;
}

.header-mobile .navbar {
  background: #2d4c87;
}

#center .breadcrumb {
  overflow: hidden;
}

.fixed {
  position: fixed;
  width: 100%;
  z-index: 999;
}

.toggle-btn-box {
  position: absolute;
}

.calendar-btn-box {
  position: absolute;
  right: 10px;
}

input[type='checkbox']#togglebox,
input[type='checkbox']#togglebox2 {
  position: absolute;
  right: 0;
  top: 0;
  visibility: hidden;
}

#offcanvas-menu {
  width: 280px;
  height: 100%;
  top: 0;
  left: -300px;
  z-index: 1000;
  box-sizing: border-box;
  background: #fff;
  display: block;
  position: fixed;
  overflow: auto;
}

#datepicker-menu {
  width: 250px;
  top: 0;
  right: -280px;
  z-index: 100;
  box-sizing: border-box;
  background: #fff;
  display: block;
  position: fixed;
  overflow: auto;
}

#offcanvas-menu label#closex {
  width: 30px;
  height: 30px;
  overflow: hidden;
  display: block;
  position: absolute;
  cursor: pointer;
  text-indent: -1000px;
  z-index: 10;
  top: 5px;
}

#closex {
  right: 5px;
}

#closex2 {
  left: 5px;
}

#closex2::before,
#offcanvas-menu label#closex::before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 6px;
  background: #2d4c87;
  top: 50%;
  margin-top: -3px;
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#closex2::after,
#offcanvas-menu label#closex::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 6px;
  background: #2d4c87;
  top: 50%;
  margin-top: -3px;
  -ms-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

#offcanvas-menu ul {
  list-style: none;
  margin-top: 200px;
  opacity: 0;
  padding: 0;
  position: relative;
  -moz-transition: margin-top 0.2s 0.3s, opacity 0.5s 0.3s;
  -webkit-transition: margin-top 0.2s 0.3s, opacity 0.5s 0.3s;
  transition: margin-top 0.2s 0.3s, opacity 0.5s 0.3s;
}

#offcanvas-menu ul li a {
  padding: 10px;
  border-radius: 20px;
}

div.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  background: #000;
  z-index: 99;
  visibility: hidden;
}

div.overlay label {
  width: 100%;
  height: 100%;
  position: absolute;
}

.loto-mien ul li,
.today-open-widget tr td {
  width: 33.333333333333%;
}

.sidebar-menu .login-button {
  width: 100%;
  margin: 0 auto 10px;
}

.sidebar-menu .login-button a {
  width: 49%;
  text-align: center;
  border: 1px solid #ccc;
  float: left;
  line-height: 30px;
  color: #282828;
}

.sidebar-menu .login-button a:first-child {
  border-right: 0;
}

.sidebar-menu .items .item.active {
  background: #fae8e3;
  border: 1px solid #e62e04;
}

.sidebar-menu .items .item .item-name a {
  color: #2d4c87;
  text-transform: uppercase;
  font-size: 13px;
}

.sidebar-menu .items .item .item-icon {
  padding: 5px;
  display: none;
}

.sidebar-menu .login-social {
  margin: 15px 0;
}

.sidebar-menu .login-social img:nth-child(2) {
  margin: 0 10px;
}

.contact-icon,
.game-icon,
.home-icon,
.lotoonline-icon,
.mothuong-icon,
.news-icon,
.search-icon,
.soicau-icon,
.soketqua-icon,
.somo-icon,
.thongke-icon,
.xoso-icon {
  display: block;
  height: 30px;
  width: 100%;
}

.smartbanner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 80px;
  line-height: 80px;
  font-family: 'Helvetica Neue', sans-serif;
  background: #f4f4f4;
  display: block;
}

.smartbanner-close,
.smartbanner-icon,
.smartbanner-info {
  display: inline-block;
  vertical-align: middle;
}

.smartbanner-container {
  vertical-align: middle;
}

.smartbanner-close {
  margin: 0 5px;
  font-family: ArialRoundedMTBold, Arial;
  font-size: 20px;
  text-align: center;
  color: #888;
  text-decoration: none;
  border: 0;
  border-radius: 14px;
  -webkit-font-smoothing: subpixel-antialiased;
}

.smartbanner-button:active,
.smartbanner-button:hover,
.smartbanner-close:active,
.smartbanner-close:hover {
  color: #aaa;
}

.smartbanner-icon {
  width: 57px;
  height: 57px;
  margin-right: 12px;
  background-size: cover;
  border-radius: 10px;
}

.smartbanner-info {
  width: 44%;
  font-size: 11px;
  line-height: 1.2em;
  font-weight: 700;
}

.smartbanner-title {
  font-size: 13px;
  line-height: 18px;
}

.smartbanner-button {
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  height: 24px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  font-weight: 700;
  color: #6a6a6a;
  text-transform: uppercase;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
}

.smartbanner-android .smartbanner-close {
  border: 0;
  width: 17px;
  height: 17px;
  line-height: 17px;
  margin-right: 7px;
  color: #b1b1b3;
  background: #1c1e21;
  text-shadow: 0 1px 1px #000;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.8) inset,
    0 1px 1px rgba(255, 255, 255, 0.3);
}

.smartbanner-android .smartbanner-close:active,
.smartbanner-android .smartbanner-close:hover {
  color: #eee;
}

.smartbanner-android .smartbanner-icon {
  background-color: transparent;
  box-shadow: none;
}

.smartbanner-android .smartbanner-info {
  color: #ccc;
  text-shadow: 0 1px 2px #000;
}

.smartbanner-android .smartbanner-title {
  color: #fff;
  font-weight: 700;
}

.smartbanner-android .smartbanner-button {
  min-width: 12%;
  color: #2d4c87;
  padding: 0;
  background: 0;
  border-radius: 0;
  box-shadow: 0 0 0 1px #333, 0 0 0 2px #dddcdc;
}

.smartbanner-android .smartbanner-button:active,
.smartbanner-android .smartbanner-button:hover {
  background: 0;
}

.smartbanner-android .smartbanner-button-text {
  text-align: center;
  display: block;
  padding: 0 10px;
  background: #fff;
  text-transform: none;
  text-shadow: none;
  box-shadow: none;
}

.box-calender h3,
.info-result .info-title,
.load-more,
.tab button {
  text-transform: uppercase;
}

.top-brand {
  height: 30px;
  border-top: 1px solid #f3f3f3;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
}

.top-logo {
  color: #e80000 !important;
  font-weight: 600;
  font-size: 18px;
  font-family: 'Times New Roman', Times, serif;
}

.top-text {
  font-size: 12px;
  color: #333;
  font-weight: 600;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.sidebar-menu.opened {
  left: 0 !important;
  transition: 0.3s ease-out;
}

.form_load .loader,
.loader-tk .image-loading-tk {
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1;
  position: absolute;
}

.bg_f6 {
  background: #f6f6f6;
}

.bg_f9 {
  background: #f9f9f9;
}

.box-calender,
.label-info,
.mega-1 {
  background-color: #2d4c87;
}

#offcanvas-menu .item-arrow {
  margin-right: 15px;
  float: right;
  transform: rotate(90deg);
}

#offcanvas-menu .item-arrow i {
  color: #2d4c87;
}

#offcanvas-menu .item-name[active] .item-arrow {
  transform: initial;
}

#offcanvas-menu .dropdown {
  opacity: 1;
  margin-top: 5px;
  display: none;
}

#offcanvas-menu .dropdown__item {
  padding: 5px 25px;
  height: 21px;
  line-height: 21px;
  margin-bottom: 0;
}

#offcanvas-menu .dropdown__item:not(:last-child) {
  border-bottom: 1px inset #ebebeb;
}

#offcanvas-menu .item-name[active] {
  background: #f90;
}

#offcanvas-menu .item-name {
  padding-left: 15px;
}

#offcanvas-menu .item {
  padding: 0;
  margin-bottom: 0;
  margin-right: 0;
}

#offcanvas-menu .dropdown__link {
  display: block;
  padding: 0;
  font-weight: 700;
}

.box-calender {
  display: none;
  justify-content: space-between;
  height: 28px;
  margin-bottom: 10px;
  align-items: center;
  border: 3px solid #2d4c87;
}

.date-bar__button {
  height: 28px;
  width: 24px;
  text-align: center;
  cursor: pointer;
  font-size: 24px;
  display: inline-block;
  vertical-align: middle;
  line-height: 24px;
}

.opened {
  display: block !important;
}

.result-box {
  border: 1px solid #d8d7d7 !important;
}

.box-calender h3 {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
}

table .capso,
table .solan {
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 29px;
  font-size: 13px;
  font-weight: 700;
}

ul.tk-sc-menu li .done_img,
ul.tk-sc-menu li .live_img,
ul.tk-sc-menu li .waiting_img {
  width: 30px !important;
}

.kq-calendar {
  width: auto;
}

.sidebar-menu {
  padding: 25px 0;
}

.mega-2 {
  background-color: #f03268;
}

.mega-3 {
  background-color: #e9b800;
}

.mega-4 {
  background-color: #69c82a;
}

.mega-5 {
  background-color: #db48c2;
}

.mega-6 {
  background-color: #ea7702;
}

.power-7 {
  background-color: #fff200;
}

.noti-skq,
.skq-submit {
  margin-top: 16px;
}

.result-box .first-col {
  font-weight: 400 !important;
}

.input2-tansuat label {
  padding-left: 16px !important;
  margin-right: 17px !important;
}

.input2-tansuat .checked-list ul {
  width: 16%;
}

table .capso {
  background: #e3e9f4;
  color: #2d4c87;
  margin-right: 3px;
}

table .solan {
  background: #fcfaf4;
  color: #464646;
}

.tooltip:after,
[data-tooltip]:after {
  display: none !important;
}

.radio.radio-border,
input.form-control,
select.form-control,
textarea.form-control {
  box-sizing: border-box;
  width: 100%;
}

.tk-mrb10 {
  margin-bottom: -10px;
}

.main-tk-sc .header-title .title-text {
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.daterangepicker.openscenter .calendar,
.daterangepicker.openscenter .ranges {
  float: left;
  margin: 4px;
}

.link-social a {
  display: inline-block;
  margin: 5px;
}

.link-social a img,
.link-social-tk a img {
  vertical-align: bottom;
  margin: 0 0 2px;
}

.link-social-tk {
  margin-top: -10px;
}

.link-social-tk a {
  display: inline-block;
  margin: 10px 15px 10px 5px;
}

.control-panel {
  display: block;
  padding: 10px;
  height: 13px;
  border-top: 1px solid #d9d9d9;
  color: #fff;
  font-weight: 700;
}

.tab,
.tabcontent {
  border: 1px solid #ccc;
}

.box-search-bdbt .radio-border,
.box-search-sqk .radio-border,
.tkts .radio-border {
  padding: 5px 0 10px;
  border: none;
}

.box-search-sqk .radio label {
  padding-left: 22px;
}

.tab {
  overflow: hidden;
  background-color: #2d4c87;
}

.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: 0;
  cursor: pointer;
  padding: 6px 16px;
  transition: 0.3s;
  font-size: 14px;
  color: #fff;
}

.loto-mien .active,
.menu-diw li.active,
.tab button.active,
.tab button:hover {
  background-color: #74aa19;
}

.tabcontent {
  display: none;
  padding: 6px 12px;
  border-top: none;
}

.no-center .table tr,
table.list-haydung tr {
  text-align: justify;
}

.tk .radio label {
  padding-left: 18px;
  margin-right: 0;
}

.radio-list ul li {
  float: left;
  margin: 5px 15px 5px 0;
}

.option-speed {
  display: none;
  margin-top: 5px;
}

.btn-option-speed {
  background: #2d4c87;
  color: #fff;
  padding: 2px 12px;
}

.table-extend tr td.cbs:last-child {
  font-weight: 400;
  border: 1px solid #666 !important;
}

.change-view {
  background: #ec971f;
  width: auto;
  float: right;
  cursor: pointer;
  padding-right: 10px;
}

.caption-info-mobie {
  background: #f4f2e6;
  font-weight: 700;
  padding: 10px;
  color: red;
  line-height: 10px;
  display: none;
}

.input-group-addon-date,
.minus,
.plus {
  height: 16px;
  padding: 11px 5px 8px;
  text-align: center;
}

.btn-ngang {
  background: #ec971f;
}

.tr-tkgan {
  background-color: #f9f9f9;
}

.tr-tkmaxgan {
  background: #fff !important;
}

.tr-tkgan td {
  padding: 3px;
  color: #900 !important;
  font-weight: 700;
  font-size: 15px;
}

.mb_madb span,
td.mb_madb {
  font-size: 18px !important;
  color: #1f10e2 !important;
}

.s17 {
  font-size: 17px;
}

.s24 {
  font-size: 24px !important;
}

.s25 {
  font-size: 25px !important;
}

.lh20 {
  line-height: 20px !important;
}

.lh22 {
  line-height: 22px !important;
}

.loto-tructiep tr td.dd-stt {
  text-align: center;
  padding-left: 0;
  font-size: 14px;
  font-weight: 400 !important;
}

td.mb_madb {
  width: 100% !important;
  line-height: 25px;
  font-weight: 400 !important;
}

.col-xs-3,
.menu-diw ul.navbar-nav li,
.quaythu .widget-content tr td {
  width: 25%;
}

.col-xs-4 {
  width: 33.33333333%;
}

.mdb6 {
  width: 16.6%;
}

@media (min-width: 992px) {
  .col-md-3 {
    width: 25%;
  }
}

.bang-loto {
  background: #f9f9f9;
  border: 1px solid #d9d9d9;
  margin-bottom: 10px;
  margin-top: -10px;
  line-height: 18px;
  border-top: none;
}

.minus,
.number-date-range input,
.plus {
  border: 1px solid #ddd;
  vertical-align: middle;
  display: inline-block;
}

.bang-loto span {
  font-size: 16px;
  font-weight: 400;
}

#ctg_content .title-text,
#sc_content .title-text {
  text-align: center;
  text-transform: uppercase;
  padding-left: 0;
}

.minus,
.plus {
  width: 20px;
}

.minus {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.plus {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.number-date-range input {
  width: 100px;
  margin-left: -5px;
  border-radius: 0;
  height: 37px;
}

.input-group-addon-date {
  width: 40px;
  background-color: #eee;
  border: 1px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  margin-left: -4px;
  margin-right: -4px;
  border-right: none;
  border-left: none;
}

.load-more,
table.list-haydung {
  width: 100%;
  margin-bottom: 10px;
}

.clred,
.clred span {
  color: #e80000 !important;
  font-size: 25px;
}

.clblue,
.clblue span {
  color: #00f !important;
  font-size: 24px;
}

.box-detail-sc {
  background: #f9f9f9;
  border: 1px solid #d9d9d9;
  margin-bottom: 10px;
  line-height: 18px;
  border-top: none;
}

#ctg_content .result-header {
  border-bottom: 1px solid #d9d9d9;
}

#ctg_content .clblue span,
#ctg_content .result-box table tr td span,
.vl-max3d table tr td span,
.vl-max4d table tr td span {
  font-size: 28px !important;
}

.info-result {
  padding: 5px;
  line-height: 25px;
  border: 1px solid #ccc;
  margin: 10px 0;
  background: #f4f2e6;
}

.info-result .info-title {
  text-align: center;
  padding: 0 5px 5px;
  color: #e00000;
  font-family: Tahoma, Geneva, sans-serif;
}

.info-result ul.sub-content li:before {
  content: '-';
  position: absolute;
  margin: -1px 0 0 -10px;
  vertical-align: middle;
}

.info-result ol li span,
p.note {
  color: #58595b;
  font-weight: 700;
}

.info-result .table-award,
.info-result .table-award td,
.info-result .table-award th {
  border: 1px solid #888;
}

.info-result .table-award td,
.info-result .table-award th {
  padding-left: 5px;
}

.see-more-title {
  margin-left: -13px;
}

.see-more {
  margin-left: 15px;
}

.see-more ul.list-html-link {
  margin-bottom: 10px;
  list-style-type: none;
}

.see-more ul.list-html-link li {
  padding: 8px 0 0;
}

.see-more a {
  text-decoration: underline !important;
  color: #120cf4 !important;
  font-size: 14px;
}

.see-more ul li:before {
  position: absolute;
  margin: 6px 0 0 -13px;
  vertical-align: middle;
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 8px;
  background: #000;
  content: '';
}

.load-more {
  line-height: 30px;
  color: #fff;
  background: #74aa19;
  font-size: 13px;
  float: left;
  border: none;
  cursor: pointer;
  font-weight: 700;
}

.icon_done,
.icon_live {
  width: 14px;
  float: right;
  margin-right: 5px;
}

.quaythu .widget-content,
.text-khdb {
  border-top: 1px solid #d8d7d7;
}

.icon_live,
.result-box table tr td.mb_madb span {
  height: 30px;
}

.title-kq {
  color: #e00000;
  font-weight: 700;
  font-size: 22px;
}

.title-link-item a,
.title-region-link a {
  text-decoration: underline;
  color: #2d4c87;
  font-size: 15px;
  font-weight: 400;
}

.result-box .result-header .title-link-item span,
.result-box .result-header .title-region-link span,
.table-tructiep .result-header .title-link-item span {
  color: #2d4c87;
  font-weight: 700;
  margin: 8px 0;
  font-size: 15px;
}

.result-box .loading-xs {
  height: 35px;
}

.loading_pending {
  padding: 4px 0;
}

.dientoan table tr td,
.skq-tong tbody tr td {
  padding: 0;
}

.unline {
  text-decoration: none !important;
}

.dientoan .result-header {
  height: auto;
  padding: 8px 0;
}

.ddxsmb .qt,
.result-header h2 {
  font-size: 18px;
  color: #e00000;
  font-weight: 700;
}

.xstn_date b,
.xstn_date span {
  font-size: 18px !important;
  font-weight: 400;
}

.h70 {
  height: 70px;
}

.title-kq-date {
  color: #2d4c87;
  font-size: 15px;
  font-weight: 700;
}

.runLoto {
  background: #c30909;
  background: linear-gradient(#c30909, #2d4c87);
  border-radius: 100%;
  font-size: 18px;
  color: #fff;
  padding: 0 5px;
  display: inline;
}

.table-tructiep .runLoto {
  font-size: 20px;
  padding: 0 4px;
}

.user-profile {
  margin-top: 5px;
  clear: both;
  padding: 0 0 0 5px;
}

.arrow {
  font-weight: 700;
  margin: 8px 0;
  font-size: 15px;
}

.xstn_date span {
  color: #1f10e2 !important;
}

.xstn_date b {
  color: #1f10e2;
}

.dbtn span.red {
  font-size: 32px;
}

.vl-max3d table tr td span.number-db,
.vl-max4d table tr td span.max4d-g1 {
  font-size: 35px !important;
}

.vl-max4d .table-khdb tr td span {
  font-size: 23px !important;
}

.text-khdb {
  padding: 10px;
  color: #00f;
  font-weight: 700;
  font-size: 15px;
  background: #f6f6f6;
}

.loto-input .tutorial {
  line-height: 20px;
}

.loto-mien {
  display: block;
  float: left;
  width: 100%;
  background: #2d4c87;
}

.loto-mien ul li {
  float: left;
  padding: 6px 0;
  text-align: center;
}

.today-open-region-widget tr td {
  border-left: 1px solid #d8d7d7;
  height: 31px;
  line-height: 31px;
  text-align: center;
}

.title-diw,
.today-open-region-widget .title-region {
  color: #fff;
  font-size: 15px;
  padding-top: 10px;
  font-weight: 700;
}

.title-tk {
  padding-left: 10px;
  font-weight: 400;
}

.title-ds-gan,
.title-tk {
  color: #fff;
  padding-top: 10px;
}

.dientoan .title-link-item {
  color: #2d4c87;
  font-weight: 700;
}

#list-number td:hover,
.vl-box .result-header,
tr.list-number td:hover {
  background: #fff2bc;
}

.icon-favourite,
.icon-nofavourite {
  width: 16px;
  display: block;
  height: 16px;
  float: left;
}

.result-box tbody td.mb_g71,
.result-box tbody td.mb_g72,
.result-box tbody td.mb_g73,
.result-box tbody td.mb_g74 {
  color: #e62e04;
  font-size: 25px;
}

.table-tructiep table .xstn_g8 {
  font-size: 28px !important;
  color: #e62e04 !important;
}

.info span.label,
span.label {
  color: #fff;
  vertical-align: baseline;
  border-radius: 0.25em;
  text-align: center;
  white-space: nowrap;
}

.somo button.button-submit {
  width: 90px;
  height: 35px;
}

.label-success {
  background-color: #73a81f;
}

.label-danger {
  background-color: #a90329;
}

.label-warning {
  background-color: #c79121;
}

span.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
}

.btn-group-skq,
.btn-skq,
.toggle-switch,
.zoom img {
  vertical-align: middle;
}

.info span.label {
  padding: 5px;
  font-size: 15px;
  font-weight: 400 !important;
  cursor: pointer;
  display: block;
  width: 130px;
}

.btn-danger,
.btn-skq,
.live-header i,
.live-loader {
  display: inline-block;
}

.sodep img {
  width: 60px;
}

.sodep span.label {
  padding: 5px;
  font-size: 90%;
}

.sodep-caudep {
  border: 1px solid #d8d7d7 !important;
  border-top: none !important;
  padding: 5px 0 5px 3px;
}

.sodep-caudep:after,
.sodep-caudep:before {
  clear: both;
  content: ' ';
  display: block;
  font-size: 0;
  height: 0;
  visibility: hidden;
}

.sodep-caudep .number {
  margin: 3px;
  padding: 5px;
  display: block;
  float: left;
  background: #fdfdfd;
  border: 1px solid #dfdddb;
  font-size: 16px;
  font-weight: 700;
  color: #e62e04;
  text-decoration: none;
}

.breadcrumbs .breadcrumb li,
.breadcrumbs .breadcrumb li a,
.breadcrumbs .breadcrumb li h2,
.breadcrumbs .breadcrumb li span,
.live-content,
.text-sponsored a {
  color: #666;
}

.widget-header h2 {
  color: #fff;
  padding-top: 9px;
  font-weight: 700;
}

.live-header {
  background-color: #f1f2f2;
  color: #414042;
  font-weight: 500;
  position: relative;
  padding: 5px;
  margin-bottom: 10px;
}

.btn-danger {
  border: 1px solid #ed1c24;
  border-radius: 15px;
  background-color: #ed1c24;
  font-weight: 700;
  padding: 2px 8px;
  color: #fff;
}

.live-header i {
  position: relative;
  top: 1px;
  left: -20px;
  height: 16px;
  width: 16px;
  text-align: center;
}

.live-loader {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  animation: 1s linear infinite alternate live-loader;
  position: absolute;
  left: 50px;
  top: -24px;
}

.btn-skq {
  font-weight: 400;
  color: #212529;
  text-align: center;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.btn-primary {
  color: #fff;
  background-color: #b13942;
  border-color: #b13942;
}

.btn-info {
  color: #fff;
  background-color: #078296;
  border-color: #078296;
}

.btn-group-skq {
  position: relative;
  display: inline-flex;
  width: 100%;
  margin-top: 10px;
}

.live-content,
.toggle,
.toggle-switch {
  display: inline-block;
}

.btn-group-skq > .btn-skq:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group-skq > .btn-skq:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-skq > .btn-skq {
  position: relative;
  flex: 1 1 auto;
}

.btn-group-skq .active {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}

.result-box table tr td.mb_madb {
  height: 30px;
  line-height: 30px;
}

@-webkit-keyframes live-loader {
  0% {
    box-shadow: -20px 40px 0 2px #fff, -10px 40px 0 0 rgb(0 82 236 / 20%),
      0 40px 0 0 rgb(0 82 236 / 20%), 10px 40px 0 0 rgb(0 82 236 / 20%);
  }

  25% {
    box-shadow: -20px 40px 0 0 rgb(0 82 236 / 20%), -10px 40px 0 2px #009444,
      0 40px 0 0 rgb(0 82 236 / 20%), 10px 40px 0 0 rgb(0 82 236 / 20%);
  }

  50% {
    box-shadow: -20px 40px 0 0 rgb(0 82 236 / 20%),
      -10px 40px 0 0 rgb(0 82 236 / 20%), 0 40px 0 2px #ed1c24,
      10px 40px 0 0 rgb(0 82 236 / 20%);
  }

  75% {
    box-shadow: -20px 40px 0 0 rgb(0 82 236 / 20%),
      -10px 40px 0 0 rgb(0 82 236 / 20%), 0 40px 0 0 rgb(0 82 236 / 20%),
      10px 40px 0 2px #fff200;
  }

  100% {
    box-shadow: -20px 40px 0 0 rgb(0 82 236 / 20%),
      -10px 40px 0 0 rgb(0 82 236 / 20%), 0 40px 0 0 rgb(0 82 236 / 20%),
      10px 40px 0 0 rgb(0 82 236 / 20%);
  }
}

@keyframes loader-live {
  0% {
    box-shadow: -20px 40px 0 2px #fff, -10px 40px 0 0 rgb(0 82 236 / 20%),
      0 40px 0 0 rgb(0 82 236 / 20%), 10px 40px 0 0 rgb(0 82 236 / 20%);
  }

  25% {
    box-shadow: -20px 40px 0 0 rgb(0 82 236 / 20%), -10px 40px 0 2px #009444,
      0 40px 0 0 rgb(0 82 236 / 20%), 10px 40px 0 0 rgb(0 82 236 / 20%);
  }

  50% {
    box-shadow: -20px 40px 0 0 rgb(0 82 236 / 20%),
      -10px 40px 0 0 rgb(0 82 236 / 20%), 0 40px 0 2px #ed1c24,
      10px 40px 0 0 rgb(0 82 236 / 20%);
  }

  75% {
    box-shadow: -20px 40px 0 0 rgb(0 82 236 / 20%),
      -10px 40px 0 0 rgb(0 82 236 / 20%), 0 40px 0 0 rgb(0 82 236 / 20%),
      10px 40px 0 2px #fff200;
  }

  100% {
    box-shadow: -20px 40px 0 0 rgb(0 82 236 / 20%),
      -10px 40px 0 0 rgb(0 82 236 / 20%), 0 40px 0 0 rgb(0 82 236 / 20%),
      10px 40px 0 0 rgb(0 82 236 / 20%);
  }
}

.live-content {
  margin-right: 28px;
  margin-left: 80px;
}

.menu-diw ul.navbar-nav {
  margin-top: 10px;
  width: 100%;
  border-top: 1px solid #dddfe2;
}

.menu-diw .nav {
  margin-top: -10px;
  padding-bottom: 10px;
}

.navbar-nav {
  float: left;
  margin: 0;
}

.navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;
}

.nav > li > a {
  padding: 10px 15px;
}

.menu-diw ul.navbar-nav li a {
  text-align: center;
  color: #333;
  font-weight: 500;
  padding: 5px 0;
  border-right: 1px solid #dddfe2;
  border-bottom: 1px solid #dddfe2;
}

.widget-skq {
  width: 67%;
  float: left;
}

.widget-skq-loto {
  width: 31.5%;
  float: right;
  margin-top: 10px;
}

.widget-skq table tr td.mb_g0 {
  font-size: 25px;
}

.widget-skq tbody td.mb_g71,
.widget-skq tbody td.mb_g72,
.widget-skq tbody td.mb_g73,
.widget-skq tbody td.mb_g74 {
  font-size: 23px;
  color: #e62e04;
}

.widget-skq .result-header {
  padding: 5px 10px;
  background: #fff2bc;
}

.sodauduoi-table {
  border-collapse: collapse;
  width: 100%;
  margin: auto;
}

.sodauduoi-table th:not(:first-child) {
  border-left: 1px solid #fff;
}

.sodauduoi-table th {
  background: #2d4c87;
  color: #fff;
}

.sodauduoi-table td:not(:empty) {
  border-left: 1px solid #ccc;
}

.sodauduoi-table td.date {
  background-color: #f1f1f1;
}

.sodauduoi-table td {
  border: none;
  line-height: 1.6em;
}

.sodauduoi-table td,
.sodauduoi-table th {
  padding: 10px 5px;
  text-align: center;
}

.sodauduoi-table td b,
.sodauduoi-table td i {
  font-size: 22px;
  font-style: normal;
}

.sodauduoi-table tr:not(.head) td {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.sodauduoi-table td:last-child {
  border-right: 1px solid #ccc;
}

.sodauduoi-table .blue,
.sodauduoi-table td b {
  color: #0555aa;
}

.sodauduoi-table tr:empty {
  height: 1rem;
}

.sodauduoi-table .g7 {
  font-weight: 700;
  font-size: 17px;
}

.sodauduoi-table .number-full,
.sodauduoi-table .number-full .r900 {
  font-weight: 700;
  font-size: 18px;
}

.action-loto {
  width: 100%;
  float: left;
  padding: 5px 0;
}

.action-loto a {
  width: 40%;
  float: left;
  padding: 5px 0 5px 24px;
  position: relative;
}

.widget-sc .result-header {
  background: #ffeca0;
}

.widget-skq .title-kq,
.widget-skq .title-kq a {
  color: #333;
  font-size: 15px;
  font-family: arial;
}

.widget-sc .title-kq,
.widget-sc .title-kq a {
  color: #e80000;
  font-size: 18px;
}

.btn-menu-cuoc,
.divAds .ads-sponsor {
  font-size: 13px;
}

.link-social-image img {
  width: 100%;
  height: 65px;
}

.main-tk-sc .Zebra_DatePicker_Icon_Wrapper {
  display: initial !important;
}

.main-tk-sc .Zebra_DatePicker_Icon {
  top: 1px !important;
}

.skq1,
.skq2,
.skq3,
.skq4,
.skq5 {
  padding-top: 10px;
  clear: both;
}

.result-box-dt tr td {
  padding: 5px;
  border: 1px solid #d9d3cb;
  text-align: center;
}

.result-box-dt tr.db .number strong {
  font-size: 27px;
  color: #e62e04 !important;
}

.result-box-dt .result-header {
  border: 1px solid #d8d7d7;
  border-bottom: none;
  background: #fff2bc;
}

.th-dientoan {
  padding: 10px;
  border-right: none;
  border-left: none;
}

.dientoan-home table a,
.kq-link:hover {
  color: #00f;
  text-decoration: underline;
}

.vl-box .title-vl {
  margin: 5px 0;
}

.vl-box .title-vl .title-a {
  color: #e00000;
  text-decoration: underline;
}

.content-sonong h3,
.content-sonong h4 {
  margin-bottom: 3px;
}

.header-title-sn {
  height: 37px;
  background: #fff2bc;
  border: 1px solid #d1d1d1;
  border-bottom: none;
  text-align: center;
}

.header-title-sn span {
  line-height: 37px;
  font-weight: 700;
  color: #f37118;
}

.kq-table-hover .btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  padding: 5px 10px;
}

.kq-table-hover {
  width: 100%;
  border: 1px solid #ccc;
}

.kq-table-hover tr td,
.kq-table-hover tr th {
  border: none;
  padding: 3px;
}

.table-extend .pad6 {
  color: #00f;
  font-weight: 500;
}

.toggle {
  cursor: pointer;
}

.toggle-switch {
  background: #ccc;
  border-radius: 16px;
  width: 50px;
  height: 25px;
  position: relative;
  transition: background 0.25s;
}

.advertisment,
.full-width-ads,
.menu-cuoc,
.zoom {
  display: none;
  text-align: center;
}

.toggle-switch:after,
.toggle-switch:before {
  content: '';
}

.toggle-switch:before {
  display: block;
  background: linear-gradient(to bottom, #fff 0, #eee 100%);
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
  width: 20px;
  height: 20px;
  position: absolute;
  top: 3px;
  left: 3px;
  transition: left 0.25s;
}

.toggle:hover .toggle-switch:before {
  background: linear-gradient(to bottom, #fff 0, #fff 100%);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
}

.toggle-2sc:checked + .toggle-switch {
  background: #c00;
}

.toggle-dau:checked + .toggle-switch {
  background: #1f10e2;
}

.toggle-dit:checked + .toggle-switch {
  background: #000;
}

.toggle-checkbox:checked + .toggle-switch:before {
  left: 28px;
}

.toggle-label {
  position: relative;
  top: 2px;
}

.number-hsc {
  color: #c00 !important;
}

.number-dau {
  color: #1f10e2 !important;
}

.number-dit {
  color: #000 !important;
}

.dd-loto-widget table tr td.dd-kq,
.dd-loto-widget tr td .red,
.loto-tructiep tr td,
.loto-tructiep tr td .red,
.result-box table tr td,
.result-box-dt tr .number strong,
.result-box-dt tr.db .number strong,
.result-table tbody tr td .date,
.result-table tbody tr td .number-full,
.result-table tbody tr td .number-full .rc00,
.table-tructiep .table tr td .number,
.tk-nhanh-number,
.tk-nhanh-number .bold {
  font-family: arial;
}

tr.list-number {
  height: 30px !important;
}

.ddxsmb .widget-header h3 {
  padding-top: 8px;
  font-size: 16px;
}

.text-sponsored {
  margin-top: 10px;
  text-align: center;
}

.advertisment .h200 {
  height: 250px;
  width: 320px;
}

.advertisment .h90 {
  height: 55px;
  width: 420px;
}

.advertisment .double-img {
  width: 48%;
  height: 200px;
}

.menu-cuoc {
  white-space: nowrap;
  overflow-x: auto;
  padding: 0 10px 10px;
}

.btn-menu-cuoc {
  border: none;
  color: #fff;
  padding: 6px 5px;
  text-align: center;
  font-weight: 700;
  border-radius: 8px;
  display: inline-block;
}

.banner-ads {
  margin: 0 auto;
  overflow: hidden;
  text-align: center;
  width: 95%;
  display: none;
}

.banner-ads-bd {
  display: inline-block;
  position: relative;
  margin: 0 auto;
  box-sizing: border-box;
}

.banner-ads-button span {
  font-size: 12px;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0;
  color: #28bc66;
  background-color: rgba(0, 0, 0, 0.7);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.ads-home-center img,
.banner-ads img {
  max-width: 100%;
  width: auto;
  height: auto;
  vertical-align: middle;
}

.banner-ads.mgb2 {
  margin-bottom: 2px !important;
}

.banner-ads.mgb10 {
  margin-bottom: 10px !important;
}

.banner-ads.mgb15 {
  margin-bottom: 15px !important;
}

.ads-home-center {
  width: 50%;
  float: left;
  padding: 5px;
  box-sizing: border-box;
}

.banner-ads-center,
.banner-home-center {
  margin: 0 auto;
  overflow: hidden;
  text-align: center;
  padding: 5px;
  box-sizing: border-box;
}

.ads-box {
  overflow: hidden;
  display: none;
}

.skq-tong tbody tr td .region-column {
  width: 100%;
  border: none;
}

.widget-gdbgan,
.widget-tong {
  background: #fff2bc;
  height: 35px;
}

.widget-gdbgan h3,
.widget-tong h3 {
  color: #333;
  text-align: center;
  padding-top: 10px;
}

@media only screen and (max-width: 1013px) {
  .full-width-ads,
  .icon-lich,
  .today-open-widget .icon,
  .toggle-btn-box {
    display: block;
  }

  .ddsmoothmenu,
  .header-wrapper,
  .hearder-logo,
  .images-404,
  .navbar-logo,
  .sidebar-menu .items,
  .top-nav {
    width: 100%;
  }

  .lmt-right {
    width: 100% !important;
    margin-top: 15px !important;
  }

  .box-calender {
    display: flex !important;
  }

  .ddsmoothmenu ul li.home,
  .header-banner,
  .header-menu-mobile .menu-item:first-child,
  .main-tk-sc #left,
  .print-kqxs,
  .result-table .scrollTop,
  .show-desktop,
  .slogan,
  .top-btns-login {
    display: none;
  }

  .header-menu-mobile .navbar {
    display: block !important;
    background: #2d4c87;
  }

  .fixed-mobile {
    position: fixed;
    width: 100%;
    z-index: 999;
  }

  .navbar-toggle {
    left: 5px;
    top: 20px;
    display: block;
    border-radius: 0;
    background: 0 0;
    position: relative;
    border: 1px solid transparent;
  }

  .sticky .navbar-toggle {
    display: block;
    padding: 11px 0 5px 10px;
    border-radius: 0;
    background: 0 0;
    position: relative;
    border: 1px solid transparent;
  }

  #offcanvas-menu,
  .sticky .icon-bar {
    background: #fff;
  }

  #offcanvas-menu {
    width: 280px;
    height: 100%;
    top: 0;
    left: -300px;
    z-index: 1000;
    box-sizing: border-box;
    display: block;
    position: fixed;
    overflow: auto;
  }

  #offcanvas-menu label#closex {
    width: 30px;
    height: 30px;
    overflow: hidden;
    display: block;
    position: absolute;
    cursor: pointer;
    text-indent: -1000px;
    z-index: 10;
    top: 5px;
  }

  #closex {
    right: 5px;
  }

  #closex2::before,
  #offcanvas-menu label#closex::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 6px;
    background: #2d4c87;
    top: 50%;
    margin-top: -3px;
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  #closex2::after,
  #offcanvas-menu label#closex::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 6px;
    background: #2d4c87;
    top: 50%;
    margin-top: -3px;
    -ms-transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }

  .sidebar-menu {
    padding: 25px 0;
  }

  .sidebar-menu .items .item {
    width: 100%;
    float: left;
    text-align: left !important;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 0 15px;
    box-sizing: border-box;
    min-height: 34px;
    line-height: 34px;
    border: none;
    border-bottom: 1px inset #ebebeb;
    background: 0 0;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .icon-bar,
  .sticky .icon-bar {
    width: 22px;
    height: 2px;
    border-radius: 1px;
    margin-bottom: 4px;
    display: block;
  }

  .icon-bar {
    background: #414141;
  }

  .text-center {
    text-align: center !important;
  }

  .sidebar-menu .logo img {
    width: 50%;
  }

  .sidebar-menu .logo-mobile p {
    margin: 10px 0;
  }

  .sidebar-menu .login-button {
    width: 100%;
    margin: 0 auto 10px;
  }

  .sidebar-menu .items .item.active {
    background: #fae8e3;
    border: 1px solid #e62e04;
  }

  .sidebar-menu .items .item .item-icon {
    padding: 5px;
  }

  #offcanvas-menu a {
    text-decoration: none;
  }

  .sidebar-menu .items .item.active .item-name a {
    color: #e62e04 !important;
  }

  .pagination {
    margin-top: 5px !important;
    padding-bottom: 10px;
  }

  .top-nav h1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  #left-big,
  #right-lt-tk {
    width: 100%;
    margin-left: 0;
  }

  #right-big {
    display: block;
    order: 2;
  }

  .scrollTop,
  .table-extend .table tbody {
    width: auto !important;
  }

  table.list-thongke {
    margin-bottom: 10px;
    width: 100%;
  }

  .link-social {
    display: block;
    margin-top: -5px;
  }

  .main-left .result-table {
    overflow: auto;
  }

  .mb-mgt10 {
    margin-top: 10px;
  }

  .navbar-logo {
    border-bottom: 1px solid #d8d7d7;
    height: 50px;
  }

  .logo-mobile .logo-mb {
    height: 40px;
    background-size: contain;
    margin-top: 5px;
    position: relative;
  }

  .logo-content {
    float: left;
    width: 100%;
    margin: auto;
  }

  .header-logo {
    float: left;
    width: 100%;
    height: 50px;
    background: #fff;
  }

  .logo-mobile {
    text-align: center;
  }

  .header-mobile {
    border-top: 1px solid #f3f3f3;
  }

  .header-menu-mobile {
    background-color: #2d4c87;
    width: 100%;
    height: 40px;
    line-height: 40px;
  }

  .sticky.header-menu-mobile .menu-item:first-child {
    float: left;
    width: 10%;
    text-align: center;
    display: block;
  }

  .header-menu-mobile .menu-item {
    float: left;
    width: 22%;
    text-align: center;
  }

  .sticky.header-menu-mobile .menu-item {
    float: left;
    width: 20%;
    text-align: center;
  }

  .header-menu-mobile .menu-item:last-child {
    float: left;
    width: 34%;
    text-align: center;
  }

  .sticky.header-menu-mobile .menu-item:last-child {
    float: left;
    width: 30%;
    text-align: center;
  }

  .header-menu-mobile .menu-item a {
    width: 100%;
    font-size: 14px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
  }

  .menu-item .active {
    color: #fff;
    background: #74aa19;
  }

  .menu-footer {
    width: auto;
  }

  .article,
  section.related {
    padding: 10px;
  }

  .kq-calendar-top {
    height: 30px;
  }

  .kq-calendar-content td {
    height: 30px;
    line-height: 30px;
  }

  .calender-mb .header-title-blue {
    height: 35px;
    text-align: center;
  }

  .calender-mb .header-title-blue .title-calender {
    color: #fff;
    padding-top: 11px;
    display: block;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
  }

  .ddsmoothmenu {
    margin-top: 0;
  }

  .ddsmoothmenu .menu-ul {
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
  }

  .header-logo-image {
    height: 40px;
  }

  .logo {
    width: 100%;
    text-align: center;
  }

  .top-content {
    height: 0;
  }

  .header-content {
    border-top: none;
  }

  .ddsmoothmenu ul li a {
    font-weight: 700;
    padding: 0 14px;
    border-right: none;
    font-size: 15px;
  }

  .minus,
  .plus {
    width: 30px;
  }

  .box-search-sqk .number-date-range input {
    width: 100px;
  }

  .result-header .title-xsmb {
    font-size: 20px;
  }

  .kq-table-hover tr td label,
  .table-extend tbody tr td:first-child,
  .table-extend tbody tr:first-child td,
  .tk-nhanh-number,
  .tk-nhanh-number .bold {
    font-size: 14px;
  }

  .link-sn {
    padding-left: 10px;
  }

  .show-mobile {
    display: block;
    font-size: 13px;
  }

  .kq-table-hover .btn-xs {
    padding: 2px 5px;
  }

  .loto-tructiep tr td,
  .table-tructiep .table tr td .number {
    font-family: arial;
  }

  .link-social a b {
    color: #666;
    font-size: 14px;
  }

  .advertisment .h90 {
    width: 320px;
    height: 50px;
  }

  .ads-overlay-bottom .advertisment .h90,
  .ads-overlay-bottom .banner-ads img {
    width: 320px;
    height: 40px;
  }

  .advertisment .h200 {
    height: 220px;
  }

  .ads-overlay-bottom {
    display: block;
    height: auto;
    visibility: visible;
    opacity: 1;
    will-change: opacity;
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    z-index: 2147483647;
    background: 0 0;
  }

  .ads-overlay-bottom-padding {
    height: 4px;
  }

  .ads-overlay-bottom-close {
    display: block;
    background-size: 13px 13px;
    background-position: center;
    background-color: #fff;
    background-repeat: no-repeat;
    border: none;
    height: 28px;
    width: 28px;
    padding: 0;
    margin: 0;
    position: absolute;
    right: 0;
    top: -27px;
    box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.2);
    border-radius: 12px 0 0;
  }

  .zoom {
    text-align: center;
    display: block;
  }

  .box-search-sqk .zoom {
    float: left;
  }

  .widget-skq table tr td {
    font-size: 24px;
  }

  .widget-skq-loto .dd-loto-widget tr td .red,
  .widget-skq-loto table tr td.dd-kq,
  .widget-skq-loto table tr td.dd-stt {
    font-size: 18px;
  }

  .title-link-item a,
  .title-region-link a {
    font-size: 14px;
    font-family: sans-serif;
  }
}

@media (max-width: 1013px) {
  #footer,
  .contact,
  .copyright {
    margin-top: 10px;
  }

  #center .widget.today-open-widget .region-column,
  #center .widget.today-open-widget .region-wrapper,
  #left,
  #offcanvas-menu .logo {
    width: 100%;
  }

  #left .live-menu,
  #left .widget-xs,
  .expant-title {
    display: none;
  }

  #center,
  #right {
    width: 100%;
    margin-left: 0;
  }

  #container,
  .top-nav {
    width: auto;
  }

  #center .dd-loto-widget table:first-child {
    float: left;
  }

  #center .dd-loto-widget .dit-loto {
    float: right;
    width: 49%;
    margin-left: 0;
  }

  #center .tk-nhanh-item-content {
    padding: 10px 10px 10px 8%;
  }

  #center .tk-nhanh-item {
    width: 32%;
    display: inline-block;
    margin-bottom: -5px;
    line-height: 1.6;
    height: auto;
    margin-right: 0;
  }

  #center .tk-nhanh-text {
    padding: 5px;
    font-weight: 400;
    font-size: 12px;
  }

  #offcanvas-menu .copyright {
    text-align: center;
    bottom: 5px;
    width: 100%;
  }

  .top-nav {
    height: 28px;
    line-height: 28px;
    padding-left: 10px;
  }

  .daterangepicker.openscenter .calendar,
  .daterangepicker.openscenter .ranges {
    float: none;
  }

  .caption-info-mobie,
  .copyright-img {
    display: block;
  }

  .see-more {
    margin-left: 25px;
  }

  .calendar-desktop,
  .home #left,
  .home .menu-sc,
  .home .menu-tk,
  .mb .menu-sc,
  .mb .menu-tk,
  .mb .tinh-dt,
  .mb .tinh-mn,
  .mb .tinh-mt,
  .mn .menu-sc,
  .mn .menu-tk,
  .mn .tinh-dt,
  .mn .tinh-mb,
  .mn .tinh-mt,
  .mt .menu-sc,
  .mt .menu-tk,
  .mt .tinh-dt,
  .mt .tinh-mb,
  .mt .tinh-mn,
  .skq #left,
  .skq #right,
  .vl .menu-sc,
  .vl .menu-tk,
  .vl .tinh-mb,
  .vl .tinh-mn,
  .vl .tinh-mt {
    display: none !important;
  }

  .news-lists {
    padding: 5px;
  }

  .top_ads {
    min-height: 50px;
    display: block;
  }

  .menu-footer {
    text-align: inherit;
    width: 100%;
    background: #f7f7f7;
    float: left;
  }

  .menu-footer .nav-menu {
    float: left;
    margin-top: 0;
  }

  .menu-footer .nav-menu .item-link {
    display: block;
    float: left;
    border-bottom: 1px solid #e7e7e7;
    color: #333;
    width: 45%;
    margin: 0 8px 0 10px;
    padding: 10px 0;
    font-size: 15px;
  }

  #footer {
    background-color: #fff;
    text-align: inherit;
    padding: 0;
  }

  .copyright-text {
    color: #999;
    padding: 15px 0 0;
    border-top: 1px solid #e7e7e7;
  }

  .copyright-info {
    padding: 15px 15px 60px;
    font: 400 14px arial !important;
    position: relative;
    margin-bottom: 0;
  }

  .copyright-img img {
    height: 40px;
  }
}

@media (min-width: 1013px) {
  .header-mobile,
  .search-widget-xs,
  .top-brand {
    display: none;
  }
}

@media (max-width: 768px) {
  .runLoto,
  .title-kq {
    font-size: 18px;
  }

  .province-list li {
    width: 287px;
  }

  .action-link,
  .ads-box,
  .advertisment,
  .banner-ads,
  .menu-cuoc {
    display: block;
  }

  .action-link ul li {
    margin-bottom: 8px;
  }

  .result-box table tr td span mark {
    font-size: 21px;
  }

  .result-box table tr td {
    height: 35px;
    line-height: 35px;
    padding: 3px 0;
  }

  .result-box table tr td.mb_g0 {
    font-size: 28px;
  }

  .widget-skq table tr td.mb_g0 {
    font-size: 23px;
  }

  .runLoto {
    padding: 0 4px;
  }

  .table-tructiep .runLoto {
    font-size: 15px;
    padding: 0 3px;
  }

  .widget-skq table tr span.mb_g0 {
    font-size: 25px;
  }

  .result-box table tr td.mb_madb span {
    height: 25px;
  }

  .result-box table tr td.mb_madb {
    height: 25px;
    line-height: 25px;
  }

  .widget-skq table tr td {
    font-size: 20px;
  }
}

@media screen and (max-width: 600px) {
  .modal-dialog {
    width: 100%;
    margin-left: 0;
  }

  .show-more br {
    display: block;
  }

  .kq-table-hover tr td {
    padding: 3px 1px;
  }

  .kq-table-hover tr td label {
    margin-right: 0;
    font-size: 13px;
  }

  .toggle {
    margin-top: 10px;
  }

  .widget-skq table tr td {
    font-size: 18px;
  }

  .widget-skq-loto .dd-loto-widget tr td .red,
  .widget-skq-loto table tr td.dd-kq,
  .widget-skq-loto table tr td.dd-stt {
    font-size: 15px;
  }
}

@media screen and (max-width: 514px) {
  .bv-mobile,
  .message-mobile {
    display: block;
  }

  .bv-desktop,
  .message-desktop {
    display: none;
  }

  .loto-input .input .numbers {
    margin-bottom: 0;
  }

  .border-red .form-group,
  .input2-tansuat .checkbox,
  .input2-tansuat .chonnhanh {
    width: 100% !important;
  }

  .checkbox-list ul li {
    float: left;
    margin: 0 -10px 10px 0;
  }

  .tkts .tktsn_day_cn,
  .tkts .tktsn_day_first,
  .xien-ghep .skq-type {
    width: 30% !important;
  }

  .tkts .tktsn_day {
    width: 17% !important;
  }

  .tkts .radio label {
    padding-left: 18px;
  }

  .border-red .zebra_datePicker_form,
  .btn-tkts {
    width: 46% !important;
  }

  .btn-ngang {
    float: left;
  }

  .border-red .zebra_datePicker_form:nth-child(2n) {
    margin-right: 0 !important;
  }

  .table-thongke tr td {
    height: 25px;
    line-height: 15px;
    padding: 2px 10px !important;
  }

  .btnPlay button {
    float: left;
    width: 45% !important;
  }

  .loto-input .btnPlay .btn-play {
    margin-left: 25px;
  }

  .loto-input .input .pull-left {
    width: 98% !important;
  }

  .result-box .power-item {
    margin: 2px;
  }

  .widget-skq tbody td.mb_g71,
  .widget-skq tbody td.mb_g72,
  .widget-skq tbody td.mb_g73,
  .widget-skq tbody td.mb_g74 {
    font-size: 21px;
  }

  .table-tructiep .table tr td .number {
    padding: 3px;
  }

  .box-search-sqk .skq-type {
    margin-bottom: -20px;
  }

  .btn-group-skq {
    margin-top: 30px;
  }

  .result-table tbody tr td .number,
  .result-table tbody tr td .number-full,
  .result-table tbody tr td .number-full .rc00 {
    font-size: 16px;
  }

  .table-extend tr td {
    font-size: 14px;
  }

  .table-tructiep .table tr td .number,
  .table-tructiep .table tr td mark {
    font-size: 22px;
  }

  .result-header .title-xsmb,
  .result-header h2,
  .title-kq {
    font-size: 16px;
    font-family: sans-serif;
  }
}

@media screen and (max-width: 425px) {
  .box-table .dauso,
  .box-table .none,
  .box-table .number {
    height: 40px;
    width: 40px;
  }

  .radio label {
    padding-left: 18px;
  }

  .cungquayxoso .xstn_date b,
  .mega645-wins.power-mega table tr td {
    font-size: 15px !important;
  }

  .diw-widget a {
    margin-right: 8px;
  }

  .diw-widget .btn-sm {
    padding: 5px 8px !important;
  }

  .full-form .form-group .label {
    text-align: initial !important;
  }

  .contact-content,
  .form-control.btn-register {
    margin-bottom: 10px;
  }

  .box-ketqua .first-col {
    font-size: 11px !important;
  }

  .result-box .mega645-item {
    margin: 5px;
  }

  .power-number .mega645-item {
    margin-right: 0 !important;
  }

  .breadcrumb > li + li:before {
    padding-right: 2px !important;
  }

  .modal-dialog {
    width: auto;
  }

  .daterangepicker .ranges .input-min {
    padding: 0 2px;
  }

  .result-table.thongkedauduoi {
    overflow: auto;
  }

  .input2-tansuat .checked-list ul,
  .search-row .Zebra_DatePicker_Icon_Wrapper {
    width: auto !important;
  }

  .sc-lt .label {
    float: none;
    line-height: none;
    width: auto;
  }

  .action button,
  .form-group button,
  .full-form .form-group .btn-register,
  .sc-lt select,
  .somo button.button-submit,
  .tabcontent .form-group {
    width: 100%;
  }

  .loto-input .select-loto .loto-xien {
    margin-right: 5px;
  }

  .loto-input {
    margin: 10px 0;
    padding: 10px;
  }

  .loto-input .input .form-control {
    width: 98%;
  }

  .loto-input .input .input-coin,
  .lotoId14,
  .lotoId15,
  .lotoId18,
  .lotoId2,
  .lotoId26,
  .lotoId27,
  .lotoId3,
  .lotoId5,
  .lotoId7,
  .select-loto .lotoId4 {
    margin-top: 5px;
  }

  .loto_today .info-today,
  .loto_today .landing-thongke {
    width: 50%;
  }

  .loto-input .btnPlay .btn-play,
  .loto_today .landing-thongke ul,
  .option-speed {
    margin-top: 0;
  }

  .loto_today .landing-thongke ul li {
    margin-bottom: 5px;
  }

  .loto-input .loto-member {
    width: 100%;
    margin-bottom: 5px;
  }

  .loto-input .loto-info {
    float: none;
  }

  .select-loto .loto-xien {
    width: 30%;
  }

  .full-form .explain {
    margin: 5px 60px 5px 0;
  }

  .daterangepicker.opensright .calendar,
  .daterangepicker.opensright .ranges {
    float: none !important;
  }

  .daterangepicker .calendar.right {
    order: 3;
  }

  .daterangepicker .calendar.left {
    order: 2;
  }

  .daterangepicker .ranges {
    order: 1;
    width: 97% !important;
  }

  .daterangepicker .daterangepicker_end_input {
    margin-right: 9px;
  }

  .box-table .dauso {
    line-height: 40px;
    font-size: 14px;
  }

  table .capso {
    width: 40px;
    height: 45px;
  }

  table .solan {
    width: 50px;
    height: 45px;
  }

  #search-box-content .form-control,
  #search-box-content .form-group,
  .Zebra_DatePicker_Icon_Wrapper,
  .search-widget .Zebra_DatePicker_Icon_Wrapper {
    width: 100% !important;
  }

  .input2-tansuat .xkq-dbcd {
    margin-top: 10px !important;
  }

  .tsds-40 .tk-nhanh-number {
    width: 18px !important;
  }

  #ctg_content .title-text,
  #sc_content .title-text,
  .list-thongke .tksc-item a,
  .sodauduoi-table td,
  .sodauduoi-table th,
  .thkqtt tbody tr td,
  .thkqtt tbody tr td span,
  .tr-tkmaxgan td:first-child,
  .widget-skq .title-region-link a {
    font-size: 14px;
  }

  .ranges .range_inputs .applyBtn {
    clear: both;
    float: left;
    margin-right: 10px;
  }

  .ranges .range_inputs .cancelBtn {
    float: left;
  }

  #center .tk-nhanh-number {
    padding: 0;
  }

  #center .vl-box table tr td:first-child {
    width: 18% !important;
  }

  .result-box .mega645-wins table tr .circle-num i {
    width: 9px;
    height: 9px;
  }

  #center .vl-max3d table tr td.first-col,
  #center .vl-max4d table tr td.first-col {
    width: 15% !important;
  }

  .message-loto {
    margin: 10px 0;
    width: 100%;
  }

  .box-search-sqk .radio label {
    padding-left: 20px;
  }

  .border-red .form-radio {
    width: 45% !important;
  }

  .checkbox-list {
    margin-top: 8px;
  }

  .radio-list ul li {
    margin: 5px 10px 5px 0;
  }

  .box-search-bdbt .bdbt-type,
  .tkgdbg .tkgdbg-type-label {
    width: 30% !important;
  }

  .action {
    height: 45px;
  }

  .action .btn-inline-default {
    margin: 10px 0 0;
  }

  .tkts .hsc_giai {
    width: 25% !important;
  }

  .tkgdbg .tkgdbg-type {
    width: 21% !important;
  }

  .tkgdbg .radio-border {
    border: none;
    padding-left: 0;
  }

  .result-table tbody tr td .date {
    font-size: 12px;
  }

  .result-table tbody tr td {
    padding: 5px 2px;
  }

  .tktsclt .checkbox-list ul li {
    margin: 0 -8px 10px 0;
  }

  .tr-tkmaxgan td {
    padding: 3px;
  }

  .table-thongke tr td.nxhgdn {
    width: 24%;
  }

  .thckdb .result-table tbody tr td {
    padding: 10px 1px;
  }

  .bsckgtt {
    width: 15%;
  }

  .dd-loto-widget table tr td.dd-kq {
    padding-left: 5px;
  }

  .article .content h2 strong,
  .article .content h3 strong,
  .madb span {
    font-size: 18px;
  }

  .number-date-range input {
    margin-top: 0 !important;
    width: 150px;
  }

  .border-red {
    border: 1px solid #e62e04;
  }

  .search-widget .Zebra_DatePicker_Icon_Wrapper input,
  .search-widget .search-number {
    width: 100%;
    box-sizing: border-box;
  }

  .search-widget .selectpicker {
    width: 100%;
    height: 35px;
    margin-top: 10px;
  }

  .search-widget .pull-left {
    width: 100%;
    height: 35px !important;
  }

  .search-widget .search-number,
  .selectpicker {
    height: 35px !important;
  }

  .search-widget select {
    margin-left: 0;
  }

  .search-widget .search-button {
    margin-left: 0;
    margin-top: 10px;
  }

  .result-box .power-item {
    margin: auto;
  }

  .user-profile {
    line-height: 20px;
  }

  .table-tructiep .table tr td .number,
  .table-tructiep .table tr td mark,
  .table-tructiep .table tr.gdb td .number {
    font-size: 21px;
  }

  .table-tructiep table .xstn_g8 {
    font-size: 25px !important;
  }

  .result-box tbody td .mb_g71,
  .result-box tbody td .mb_g72,
  .result-box tbody td .mb_g73,
  .result-box tbody td .mb_g74 {
    color: #e62e04;
    font-size: 23px;
  }

  .news-item .thumbnail {
    width: 120px;
  }

  .news-item a.img img {
    height: 68px;
  }

  .article-title h1 {
    font-size: 22px;
    line-height: 1.5;
  }

  .article .article-content .short-description,
  .article .article-content .short-description h2 {
    font: 600 16px/1.5 arial;
    margin: 0 0 1em;
    word-break: break-word;
  }

  .article .content p {
    font: 400 16px/1.5 arial;
    margin: 0 0 1em;
    word-break: break-word;
  }

  .article .content h2 {
    font: 700 16px/1.5 arial;
    margin: 0 0 1em;
    word-break: break-word;
  }

  .article .content p strong,
  section.related h3,
  section.related ul li a {
    font-size: 16px;
  }

  .table-xemloto tbody tr td:first-child {
    width: 20%;
  }

  .icon-favourite,
  .icon-nofavourite {
    padding-right: 5px;
  }

  .box-table .number .num {
    font-size: 20px;
  }

  .box-table .number span {
    font-size: 13px;
  }

  .widget-skq-loto .dau-loto {
    width: 5%;
  }

  .widget-skq .result-header h2 {
    font-size: 5px;
  }

  .sodauduoi-table td b,
  .sodauduoi-table td i {
    font-size: 20px;
    font-style: normal;
  }

  .result-table tbody tr td .number,
  .result-table tbody tr td .number-full,
  .result-table tbody tr td .number-full .rc00 {
    font-size: 15px;
  }

  .widget-skq table tr span.mb_g0 {
    font-size: 23px;
  }

  .btn-danger {
    padding: 2px 5px;
    margin-left: -10px;
  }

  .live-content {
    margin-left: 50px !important;
  }

  .kq-table-hover tr td {
    padding: 3px 1px;
  }
}

@media (max-width: 393px) {
  .box-table .dauso,
  .box-table .none {
    width: 8%;
    height: 25px;
    line-height: 25px;
  }

  .box-table .number {
    width: 8%;
    height: 25px;
  }

  .result-box .mega645-wins table tr .circle-num i {
    width: 9px;
    height: 9px;
    margin: 0 2px 0 0;
  }

  .tkts .tktsn_day {
    margin-right: 8px;
  }

  .tkts .tktsn_day_first {
    width: 32% !important;
    margin-right: 8px;
  }

  .tkts .hsc_giai {
    width: 30% !important;
  }

  .tktsclt .checkbox-list ul li {
    margin: 0 -10px 10px 0;
  }

  .sticky .navbar-toggle {
    padding: 11px 0 5px 8px;
  }

  .live-content {
    margin-left: 60px;
    font-size: 14px;
  }

  .btn-danger {
    font-size: 13px;
  }

  .btn-menu-cuoc,
  .result-table tbody tr td .date {
    font-size: 11px;
  }

  .widget-skq-loto {
    width: 31.4%;
  }

  .widget-skq .result-header h2 {
    font-size: 2px;
  }

  .widget-skq .title-region-link a,
  .widget-skq-loto table tr td.dd-kq,
  .widget-skq-loto table tr td.dd-kq .red {
    font-size: 14px;
  }

  .result-box .power-item,
  .result-box table tr td {
    font-size: 22px;
  }

  .table-tructiep table .xstn_g8 {
    font-size: 26px !important;
  }

  .result-box table tr td.mb_g0 {
    font-size: 24px;
  }

  .table-tructiep .table tr td .number,
  .table-tructiep .table tr td mark {
    font-size: 20px;
  }

  .widget-skq table tr td {
    font-size: 17px;
  }

  .widget-tong {
    height: 53px;
  }
}

@media (max-width: 385px) {
  .result-box .mega645-item {
    font-size: 20px;
    font-weight: 700;
  }

  .result-box .power-item {
    padding: 8px;
  }

  .table-tructiep .table thead tr th a {
    font-size: 13px;
  }

  .sodauduoi-table td,
  .sodauduoi-table th {
    padding: 5px;
  }

  .sodauduoi-table td b,
  .sodauduoi-table td i {
    font-size: 18px;
  }
}

@media (max-width: 375px) {
  .dbnmfirst,
  .region-column li a,
  .tk-nhanh-number,
  .tk-nhanh-number .bold {
    font-size: 14px;
  }

  .tk-nhanh-item-header {
    word-spacing: 1px;
  }

  .diw-widget a,
  .widget-header h3 a {
    margin-right: 0;
  }

  .tk-nhanh-40 .update {
    font-size: 11px;
    margin-right: 0;
  }

  .result-box .jackpot-amount-box {
    padding: 5px;
  }

  .mega645-wins.power-mega table tr td {
    font-size: 13px !important;
  }

  #center .tk-nhanh-text {
    width: auto;
  }

  .diw-widget .btn-sm {
    padding: 5px !important;
  }

  .list-thongke .tksc-item {
    line-height: normal;
  }

  table.list-thongke tr {
    text-align: justify;
  }

  .checkbox-list ul li {
    float: left;
    margin: 0 -6px 10px 0;
  }

  .caption-info-mobie {
    padding: 5px;
    line-height: 15px;
  }

  .ktgck {
    width: 41%;
  }

  .tksc-item a {
    line-height: 22px;
  }

  .result-box .vl-max4d table tr td.number {
    font-size: 20px;
  }

  .line-power {
    height: 41px !important;
  }

  .sticky .navbar-toggle {
    padding: 11px 0 5px 8px;
  }

  .table-lotogan .widget-header {
    height: 45px;
  }

  .dd-loto-widget table th,
  .loto-tructiep tr th,
  .table-tructiep .table thead tr th {
    font-size: 13px;
  }

  .box-table .number span {
    font-size: 12px;
  }
}

@media (max-width: 363px) {
  .result-table tbody tr td .date {
    font-size: 10px;
  }

  #center .tk-nhanh-number {
    width: auto;
    padding: 5px;
    font-size: 14px;
  }

  #center .tk-nhanh-number .bold {
    font-size: 14px !important;
  }

  .dbnmfirst {
    font-size: 15px;
  }

  .result-box .power-item {
    padding: 6px;
  }

  .result-box .mega645-item {
    margin: auto;
  }

  .menu-footer .nav-menu .item-link {
    margin-left: 8px;
  }

  .mb_madb span {
    font-size: 17px !important;
  }

  .result-table tbody tr td .number,
  .result-table tbody tr td .number-full,
  .result-table tbody tr td .number-full .rc00 {
    font-size: 14px;
  }

  .live-loader {
    left: 22px !important;
  }

  .btn-danger {
    padding: 0 2px;
    margin-left: -15px;
  }

  .live-content {
    margin-left: 40px !important;
    margin-right: 20px;
  }

  .kq-table-hover tr td {
    padding: 3px 0;
  }
}

@media (max-width: 339px) {
  .tk-nhanh-item-header,
  .widget-header {
    padding-left: 2px;
  }

  .mega-col {
    font-size: 13px !important;
  }

  .box-search-bdbt .bdbt-type,
  .xien-ghep .xien-type-1 {
    width: 35% !important;
  }

  .checkbox-list ul li {
    float: left;
    margin: 0 -8px 10px 0;
  }

  .xien-ghep .xien-type-2,
  .xien-ghep .xien-type-3 {
    width: 25% !important;
  }

  .tkts .tktsn_day_first {
    width: 40% !important;
  }

  .tkts .tktsn_day {
    width: 20% !important;
  }

  .tkts .hsc_giai {
    width: 33% !important;
    margin-right: 0;
  }

  .ktgck {
    width: 34%;
  }

  .widget-header {
    padding-right: 2px;
  }

  .table-lotogan .widget-header {
    padding-left: 10px;
  }

  .tk-nhanh-40 .update {
    font-size: 10px;
  }

  #sc_content .header-title-silver h3,
  .region-column li a {
    font-size: 12px;
  }

  .box-table .number a {
    font-size: 18px;
  }

  .region-column ul li {
    padding-left: 5px;
  }

  .icon_done,
  .icon_live {
    margin-right: 2px;
  }

  .title-ds-gan {
    font-size: 13px;
  }

  .live-content {
    font-size: 14px;
    margin-left: 35px;
  }

  .live-loader {
    left: 25px;
  }

  .tk-nhanh-40 h2 {
    font-size: 14px !important;
  }

  .widget-skq,
  .widget-skq-loto {
    width: 100% !important;
  }

  .kq-table-hover .btn-xs {
    padding: 2px 3px;
  }

  .day-skq {
    display: none;
  }
}

@media (max-width: 320px) {
  .result-box .mega645-item {
    padding: 8px;
  }

  #center .vl-box table tr td.first-col {
    width: 11% !important;
  }

  .result-box .mega645-wins table tr td {
    padding-left: 2px;
  }

  .diw-widget .btn-sm {
    padding: 5px 1px !important;
  }

  .box-ketqua .result-box {
    width: 100% !important;
  }

  .result-box-loto {
    width: 100%;
    margin-bottom: 10px;
  }

  #sc_content .header-title-silver {
    padding-left: 2px !important;
  }

  .loto_today .landing-thongke ul li {
    margin-left: 15px;
  }

  .tk-nhanh-item-header {
    height: 35px;
    line-height: 35px;
    padding-left: 1px;
  }

  .result-box .mega645-wins table tr .circle-num i {
    width: 8px;
    height: 8px;
    margin: 2px 2px 0 0;
  }

  .power-line-jack {
    height: 8px !important;
  }

  .power-number .mega645-item {
    font-size: 22px !important;
    padding: 6px !important;
  }

  .input2-tansuat label {
    padding-left: 16px !important;
    margin-right: 22px !important;
  }

  .table-tructiep .table tr.gdb td .number {
    font-size: 20px;
  }

  .result-table tbody tr td .date {
    font-size: 10px;
  }

  .cn ul li {
    margin: 5px 5px 5px 0;
  }

  .tk-nhanh-item-header,
  .tk-nhanh-item-header .red {
    font-size: 13px;
    white-space: nowrap;
  }

  #center .tk-nhanh-number,
  #center .tk-nhanh-number .bold {
    padding-left: 0;
    padding-right: 0;
  }

  .hot2-social {
    display: none;
  }

  .result-box .power-item {
    padding: 5px;
  }

  .title-link-item a {
    font-size: 14px;
  }

  .title-kq {
    font-size: 17px;
  }

  .clblue,
  .clblue span {
    color: #00f;
    font-size: 23px;
  }

  .loto-tructiep tr th,
  .table-tructiep .table thead tr th {
    font-size: 12px;
  }

  #center .vl-max4d table tr td:first-child {
    width: 15% !important;
  }

  .sticky .navbar-toggle {
    padding: 11px 0 5px 5px;
  }

  .result-table tbody tr td {
    font-size: 12px;
    padding: 1px;
  }

  .result-box table tr td,
  .result-box table tr td span,
  .result-box table tr td span mark,
  .sblue {
    font-size: 22px;
  }

  .widget-header h3 {
    font-size: 13px;
  }
}

@media screen and (max-width: 512px) {
  .full-form .form-group .label {
    text-align: inherit !important;
  }

  .contact-content {
    margin-bottom: 10px;
  }

  .diw-widget a {
    margin-right: 0;
  }

  .widget-header h2 {
    font-size: 15px;
    padding-top: 9px;
  }

  .widget-skq-loto {
    width: 31.4%;
  }

  #bdbt thead tr th {
    font-size: 14px;
  }

  .result-table tbody tr td {
    padding: 5px 2px;
  }

  .result-table tbody tr td .date {
    font-size: 13px;
  }

  .box-search-bdbt .bdbt-type,
  .tkgdbg .tkgdbg-type-label {
    width: 30% !important;
  }

  .label.lb-tk,
  .radio.radio-border,
  select.form-control,
  textarea.form-control {
    margin-top: 5px !important;
  }

  .box-search-sqk .bdn,
  .number-date-range {
    margin-top: 5px;
  }

  .live-loader {
    left: 35px;
  }

  .live-content {
    margin-left: 60px;
  }

  .kq-table-hover tr td label {
    font-size: 12px;
  }
}

@media screen and (min-width: 426px) and (max-width: 460px) {
  .btn-sm {
    padding: 5px 9px;
  }
}

@media screen and (min-width: 377px) and (max-width: 418px) {
  .diw-widget .btn-sm {
    padding: 5px !important;
  }
}

@media screen and (min-width: 322px) and (max-width: 363px) {
  .diw-widget .btn-sm {
    padding: 5px 2px !important;
  }
}

.Zebra_DatePicker *,
.Zebra_DatePicker :after,
.Zebra_DatePicker :before {
  -moz-box-sizing: content-box !important;
  -webkit-box-sizing: content-box !important;
  box-sizing: content-box !important;
}

.Zebra_DatePicker {
  position: absolute;
  background: #fff;
  border: 1px solid #999;
  z-index: 1200;
  padding: 5px;
  top: 0;
}

.Zebra_DatePicker * {
  margin: 0;
  padding: 0;
  color: #373737;
  background: 0 0;
  border: 0;
}

.Zebra_DatePicker table {
  width: auto;
  table-layout: auto;
}

.Zebra_DatePicker td,
.Zebra_DatePicker th {
  text-align: center;
  padding: 5px 0;
}

.Zebra_DatePicker .dp_daypicker td,
.Zebra_DatePicker .dp_daypicker th,
.Zebra_DatePicker .dp_header .dp_next,
.Zebra_DatePicker .dp_header .dp_previous,
.Zebra_DatePicker .dp_monthpicker td,
.Zebra_DatePicker .dp_yearpicker td {
  width: 30px;
}

.Zebra_DatePicker,
.Zebra_DatePicker .dp_footer .dp_hover,
.Zebra_DatePicker .dp_header .dp_hover,
.Zebra_DatePicker td.dp_hover,
.Zebra_DatePicker td.dp_selected {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.Zebra_DatePicker.dp_visible {
  visibility: visible;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.Zebra_DatePicker.dp_hidden {
  visibility: hidden;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
}

.Zebra_DatePicker .dp_header .dp_hover {
  background: #dedede;
  color: #373737;
}

.Zebra_DatePicker td.dp_disabled,
.Zebra_DatePicker td.dp_not_in_month,
.Zebra_DatePicker td.dp_weekend_disabled {
  color: #dedede;
  cursor: default;
}

.Zebra_DatePicker td.dp_selected {
  background: #039;
  color: #fff !important;
}

.Zebra_DatePicker td.dp_week_number {
  cursor: text;
  font-weight: 700;
}

.Zebra_DatePicker .dp_monthpicker td,
.Zebra_DatePicker .dp_yearpicker td {
  width: 33%;
}

.Zebra_DatePicker td.dp_current,
.Zebra_DatePicker td.dp_disabled_current {
  color: #3a87ad;
}

.Zebra_DatePicker td.dp_hover {
  background: #dedede;
}

button.Zebra_DatePicker_Icon_Inside {
  margin: 0 3px 0 0;
}

#toc {
  background: #f9f9f9;
  border: 1px solid #d8d7d7;
  padding: 10px;
  margin-bottom: 1em;
  width: auto;
  display: table;
  font-size: 95%;
}

#toc a {
  text-decoration: none;
  color: #01b7f2;
  line-height: 26px;
}

.contentify_title {
  text-align: center;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.contentify_0 {
  margin-top: 1em !important;
}

.contentify_1 > li {
  font-size: medium;
  padding-left: 1em;
}

.contentify_2 > li {
  font-size: small;
  padding-left: 2em;
}
